// We don't really need this component anymore.
/* eslint-disable */
import * as PIXI from 'pixi.js';

export class GridElement {
  verticalLines: PIXI.Graphics[] = [];
  horizontalLines: PIXI.Graphics[] = [];

  constructor(canvasWidth: number, canvasHeight: number) {
    // Create grid.
    for (let i = 0; i <= 3 * 2400; i += 200) {
      let line = new PIXI.Graphics();
      line.lineStyle(2, 0xeeeeee, 0.2);
      line.moveTo(0, i);
      line.lineTo(4 * 2400, i);
      this.horizontalLines.push(line);
    }
    for (let i = 0; i <= 4 * 2400; i += 200) {
      let line = new PIXI.Graphics();
      line.lineStyle(2, 0xeeeeee, 0.2);
      line.moveTo(i, 0);
      line.lineTo(i, 3 * 2400);
      this.verticalLines.push(line);
    }
  }

  update(canvasWidth: number, canvasHeight: number, offsetX: number, offsetY: number, scale: number) {
    // for (let line of this.horizontalLines) {
    //   line.position.x = -offsetX;
    // }
    // for (let line of this.verticalLines) {
    //   line.position.y = -offsetY;
    // }
  }

  render(stage: PIXI.Container) {
    for (let line of this.horizontalLines) {
      stage.addChild(line);
    }
    for (let line of this.verticalLines) {
      stage.addChild(line);
    }
  }
}
