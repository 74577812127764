import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { AppProps } from '../App';
import logo from '../assets/ctechnology_black_noborder.png';
import { checkLoggedIn } from '../util/LoginChecker';
import serverUrl from '../util/Urls';
import './styles/LoginScreen.scss';

/** Props of the login component. */
type LoginProps = {
  onLogin?: () => void;
};

/** The login component is what a user sees first when going to app.clickahoy.com. */
export default class LoginScreen extends React.Component<RouteComponentProps & AppProps & LoginProps> {
  state = {
    email: '',
    password: '',
    isLoading: false,
    loginError: false,
  };
  refLoginBox: React.RefObject<HTMLDivElement>;

  constructor(props: RouteComponentProps & AppProps & LoginProps) {
    super(props);
    this.refLoginBox = React.createRef();
  }

  componentDidMount(): void {
    // Check if this user is already logged in. We pass along the this.props.onLogin, which
    // (usually) refers to onLogin defined in App.tsx (loads all boats anew).
    checkLoggedIn(this.props.setAppState, this.props.history, this.props.onLogin);
  }

  /** What happens if a user clicks on "login". */
  onLogin = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    this.setState({ isLoading: true });

    const url = serverUrl + '/api/v1.0/auth/ops/login';
    window
      .fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: this.state.email.trim(), password: this.state.password.trim() }),
        mode: 'cors',
        credentials: 'include',
      })
      .then((_) => _.json())
      .then((res) => {
        if (res['user']['email'] === this.state.email.trim() && res['token']) {
          localStorage.setItem('UserToken', res['token']);
          this.setState({ isLoading: false });
          checkLoggedIn(this.props.setAppState, this.props.history, this.props.onLogin);
        } else {
          this.setState({ isLoading: false });
          this.shakeLoginBox();
        }
      })
      .catch((_) => {
        this.setState({ loginError: true, isLoading: false });
        this.shakeLoginBox();
      });
  };

  /** Shakes the login box to show that the login didn't work. */
  shakeLoginBox = (): void => {
    this.refLoginBox.current?.classList.add('animate__animated');
    this.refLoginBox.current?.classList.add('animate__shakeX');
    setTimeout(() => {
      this.refLoginBox.current?.classList.remove('animate__animated');
      this.refLoginBox.current?.classList.remove('animate__shakeX');
    }, 2000);
  };

  render(): React.ReactElement {
    const loadingAnimation = this.state.isLoading ? (
      <span>
        <span
          className="spinner-border"
          style={{ width: '1rem', height: '1rem' }}
          role="status"
          aria-hidden="true"
        ></span>
        <span className="sr-only">Logging you in...</span>
      </span>
    ) : (
      <span>Login</span>
    );
    const loginError = this.state.loginError ? (
      <div style={{ marginBottom: '1rem' }}>Wrong credentials, please try again.</div>
    ) : (
      <span></span>
    );

    return (
      <div className="login-wrap">
        <div className="container login">
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <a href="https://ctechnology.io">
                <div className="img-wrap">
                  <img src={logo} className="main-pic" alt="logo" />
                </div>
              </a>

              <div className="login-box animate__animated" ref={this.refLoginBox}>
                <h5 className="login-title">Login to c.technology</h5>

                <form onSubmit={(event) => this.onLogin(event)} className="login-form">
                  <p style={{ marginTop: '3rem' }}>
                    <label htmlFor="id_email">Email Address</label>
                    <br />
                    <input
                      type="email"
                      name="email"
                      maxLength={254}
                      autoFocus={true}
                      required={true}
                      id="id_email"
                      style={{ width: '100%' }}
                      value={this.state.email}
                      onChange={(event) => this.setState({ email: event.target.value })}
                    />
                    <br />
                  </p>

                  <p>
                    <label htmlFor="id_password">Password</label>
                    <br />
                    <input
                      type="password"
                      name="password"
                      required={true}
                      id="id_password"
                      value={this.state.password}
                      onChange={(event) => this.setState({ password: event.target.value })}
                    />
                  </p>
                  {loginError}

                  <button className="btn btn-primary" disabled={this.state.isLoading} type="submit">
                    {loadingAnimation}
                  </button>

                  <p style={{ marginTop: '12px' }}>
                    <a href="https://clickahoy.com/accounts/password_reset">Forgot password?</a>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
