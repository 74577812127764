// We don't really need this component anymore.
/* eslint-disable */
import * as PIXI from 'pixi.js';
import { InteractionEvent, InteractivePointerEvent } from 'pixi.js';
import { Module, ModuleOutcomeState } from '../pipeline/Module';
import { SocketType } from '../pipeline/Socket';

export class ModuleElement {
  strokeWeight = 16;
  moduleWidth = 400;
  moduleHeaderHeight = 48;
  connectorPaddingTop = 24;
  connectorHeight = 28;
  connectorWeight = 2;
  titleFontSize = 20;
  connectorFontSize = 14;
  textPadding = 12;

  module: Module;
  modulePosX: number;
  modulePosY: number;
  moduleHeight: number;

  moduleContainer: PIXI.Container;
  linksConnected: string[] = [];

  outLinks: any[] = [];
  inLinks: any[] = [];

  styles = {
    titleStyle: new PIXI.TextStyle({
      fontFamily: 'Roboto Light',
      fontSize: this.titleFontSize,
      fill: 'white',
    }),
    connectorStyleLeftAlign: new PIXI.TextStyle({
      fontFamily: 'Roboto Light',
      fontSize: this.connectorFontSize,
      fill: 'white',
    }),
    connectorStyleRightAlign: new PIXI.TextStyle({
      fontFamily: 'Roboto Light',
      fontSize: this.connectorFontSize,
      fill: 'white',
      align: 'right',
    }),
    connectorStyleLeftAlignTransp: new PIXI.TextStyle({
      fontFamily: 'Roboto Light',
      fontSize: this.connectorFontSize,
      fill: 'rgba(255, 255, 255, 0.3)',
    }),
    connectorStyleRightAlignTransp: new PIXI.TextStyle({
      fontFamily: 'Roboto Light',
      fontSize: this.connectorFontSize,
      fill: 'rgba(255, 255, 255, 0.3)',
      align: 'right',
    }),
    connectorStyleLeftAlignTranspItalic: new PIXI.TextStyle({
      fontFamily: 'Roboto Light',
      fontSize: this.connectorFontSize,
      fontStyle: 'italic',
      fill: 'rgba(255, 255, 255, 0.3)',
    }),
    connectorStyleRightAlignTranspItalic: new PIXI.TextStyle({
      fontFamily: 'Roboto Light',
      fontSize: this.connectorFontSize,
      fontStyle: 'italic',
      fill: 'rgba(255, 255, 255, 0.3)',
      align: 'right',
    }),
  };

  constructor(module: any) {
    this.module = module;
    this.modulePosX = module.position.x;
    this.modulePosY = module.position.y;
    this.moduleHeight =
      this.moduleHeaderHeight +
      this.connectorPaddingTop +
      Math.max(
        Math.max(Object.keys(this.module.inputSockets).length, Object.keys(this.module.outputSockets).length) *
          this.connectorHeight,
        this.connectorHeight
      );

    this.moduleContainer = new PIXI.Container();
  }

  getSocketPosition(socketName: string, socketType: SocketType) {
    switch (socketType) {
      case SocketType.Input:
        let posIn = Object.values(this.module.inputSockets).findIndex((e: any) => e['name'] === socketName);
        return {
          x: this.moduleContainer.position.x,
          y:
            this.moduleContainer.position.y +
            this.moduleHeaderHeight +
            this.connectorPaddingTop +
            posIn * this.connectorHeight,
        };
      case SocketType.Output:
        let posOut = Object.values(this.module.outputSockets).findIndex((e: any) => e['name'] === socketName);
        return {
          x: this.moduleContainer.position.x + this.moduleWidth,
          y:
            this.moduleContainer.position.y +
            this.moduleHeaderHeight +
            this.connectorPaddingTop +
            posOut * this.connectorHeight,
        };
    }
  }

  addConnectedLink(linkName: string) {
    this.linksConnected.push(linkName);
  }

  render(
    stage: PIXI.Container,
    onModuleOver: (moduleElement: ModuleElement) => void,
    onModuleOut: (moduleElement: ModuleElement) => void,
    onModuleMouseDown: (moduleElement: ModuleElement) => void,
    onModuleMouseUp: (moduleElement: ModuleElement) => void,
    onModuleMouseMove: (ev: InteractivePointerEvent, moduleElement: ModuleElement) => void,
    onModuleClick: (ev: InteractivePointerEvent, moduleElement: ModuleElement) => void
  ) {
    // Draw background box.
    let boxBackground = new PIXI.Graphics();
    boxBackground.lineStyle(0, 0x000000, 0);
    boxBackground.beginFill(0x4c4c4c);
    boxBackground.moveTo(0, 0);
    boxBackground.drawRoundedRect(0, 0, this.moduleWidth, this.moduleHeight, this.strokeWeight);
    boxBackground.endFill();
    this.moduleContainer.addChild(boxBackground);

    let boxHeader = new PIXI.Graphics();
    boxHeader.lineStyle(0, 0x000000, 0);
    if (this.module.outcomeState === ModuleOutcomeState.Failure) {
      boxHeader.beginFill(0xee4499);
    } else {
      boxHeader.beginFill(0x4978bc);
    }
    boxHeader.drawRoundedRect(0, 0, this.moduleWidth, this.moduleHeaderHeight, this.strokeWeight);
    boxHeader.endFill();
    this.moduleContainer.addChild(boxHeader);

    let title = new PIXI.Text(this.module.name, this.styles.titleStyle);
    title.anchor.set(0, 0.5);
    title.position.set(this.textPadding, this.moduleHeaderHeight / 2);
    this.moduleContainer.addChild(title);

    // Create connectors: In first.
    let i = 0;
    Object.keys(this.module.inputSockets).forEach((key: any, idx: any) => {
      let connector = this.module.inputSockets[key];
      let bubble = new PIXI.Graphics();
      bubble.lineStyle(this.connectorWeight, 0x2c2c2c, 1);
      bubble.beginFill(0x4978bc);
      bubble.drawCircle(0, 0, this.connectorHeight / 6);
      bubble.endFill();
      bubble.x = 0;
      bubble.y = this.moduleHeaderHeight + this.connectorPaddingTop + i * this.connectorHeight;
      this.moduleContainer.addChild(bubble);

      let descriptorUnit = new PIXI.Text(
        '[' + connector.socketDataType + ']',
        this.styles.connectorStyleLeftAlignTransp
      );
      descriptorUnit.anchor.set(0, 0.5);
      descriptorUnit.position.set(
        this.textPadding,
        this.moduleHeaderHeight + this.connectorPaddingTop + i * this.connectorHeight
      );
      this.moduleContainer.addChild(descriptorUnit);

      let descriptorName = new PIXI.Text(connector.name.split('-')[1], this.styles.connectorStyleLeftAlign);
      descriptorName.anchor.set(0, 0.5);
      descriptorName.position.set(
        1.8 * this.textPadding + descriptorUnit.width,
        this.moduleHeaderHeight + this.connectorPaddingTop + i * this.connectorHeight
      );
      this.moduleContainer.addChild(descriptorName);

      let val = this.module.moduleInputs[connector.name.split('-')[1]];
      let valRepr = `${val}`;
      if (Array.isArray(val) && val.length > 0) {
        valRepr = `[${val[0]}, ...]`;
      }
      let descriptorValues = new PIXI.Text(valRepr, this.styles.connectorStyleLeftAlignTranspItalic);
      descriptorValues.anchor.set(0, 0.5);
      descriptorValues.position.set(
        2.6 * this.textPadding + descriptorUnit.width + descriptorName.width,
        this.moduleHeaderHeight + this.connectorPaddingTop + i * this.connectorHeight
      );
      this.moduleContainer.addChild(descriptorValues);
      i += 1;
    });

    // Create connectors: Out second.
    i = 0;
    Object.keys(this.module.outputSockets).forEach((key: any, idx: any) => {
      let connector = this.module.outputSockets[key];
      let bubble = new PIXI.Graphics();
      bubble.lineStyle(this.connectorWeight, 0x2c2c2c, 1);
      bubble.beginFill(0x4978bc);
      bubble.drawCircle(0, 0, this.connectorHeight / 6);
      bubble.endFill();
      bubble.x = this.moduleWidth;
      bubble.y = this.moduleHeaderHeight + this.connectorPaddingTop + i * this.connectorHeight;
      this.moduleContainer.addChild(bubble);

      let descriptorUnit = new PIXI.Text(
        '[' + connector.socketDataType + ']',
        this.styles.connectorStyleLeftAlignTransp
      );
      descriptorUnit.anchor.set(1, 0.5);
      descriptorUnit.position.set(
        this.moduleWidth - this.textPadding,
        this.moduleHeaderHeight + this.connectorPaddingTop + i * this.connectorHeight
      );
      this.moduleContainer.addChild(descriptorUnit);

      let descriptorName = new PIXI.Text(connector.name.split('-')[1], this.styles.connectorStyleRightAlign);
      descriptorName.anchor.set(1, 0.5);
      descriptorName.position.set(
        this.moduleWidth - (1.8 * this.textPadding + descriptorUnit.width),
        this.moduleHeaderHeight + this.connectorPaddingTop + i * this.connectorHeight
      );
      this.moduleContainer.addChild(descriptorName);

      let val = this.module.moduleOutputs[connector.name.split('-')[1]];
      let valRepr = `${val}`;
      if (Array.isArray(val) && val.length > 0) {
        valRepr = `[${val[0]}, ...]`;
      }
      let descriptorValues = new PIXI.Text(valRepr, this.styles.connectorStyleLeftAlignTranspItalic);
      descriptorValues.anchor.set(1, 0.5);
      descriptorValues.position.set(
        this.moduleWidth - (2.6 * this.textPadding + descriptorUnit.width + descriptorName.width),
        this.moduleHeaderHeight + this.connectorPaddingTop + i * this.connectorHeight
      );
      this.moduleContainer.addChild(descriptorValues);
      i += 1;
    });

    this.moduleContainer.position.x = this.modulePosX;
    this.moduleContainer.position.y = this.modulePosY;
    switch (this.module.outcomeState) {
      case ModuleOutcomeState.Failure:
        /* Do nothing, header already painted red. */ break;
      case ModuleOutcomeState.NotExecuted:
        this.moduleContainer.alpha = 0.3;
        break;
      case ModuleOutcomeState.Success:
        /* Do nothing. */ break;
    }

    boxBackground.interactive = true;
    boxBackground.on('mouseover', (e: InteractionEvent) => {
      onModuleOver(this);
    });
    boxBackground.on('mouseout', (e: InteractionEvent) => {
      onModuleOut(this);
    });
    boxBackground.on('mousemove', (e: InteractionEvent) => {
      onModuleMouseMove(e.data.originalEvent, this);
    });
    boxBackground.on('mousedown', (e: InteractionEvent) => {
      e.data.originalEvent.stopPropagation();
      e.data.originalEvent.preventDefault();
      onModuleMouseDown(this);
    });
    boxBackground.on('mouseup', (e: InteractionEvent) => {
      onModuleMouseUp(this);
    });
    boxBackground.on('mouseupoutside', (e: InteractionEvent) => {
      onModuleMouseUp(this);
    });
    boxBackground.on('click', (e: InteractionEvent) => {
      e.stopPropagation();
      onModuleClick(e.data.originalEvent, this);
    });

    // add it the stage so we see it on our screens..
    stage.addChild(this.moduleContainer);
  }
}
