import React from 'react';
import { AppProps } from '../App';

/**
 * Lets components easily build input change handlers that set the
 * state property corresponding to the input element's name.
 *
 * @param component The component for which the state should be set, usually "this".
 * @returns A function that automatically updates the state property corresponding to the name of the input element.
 */
export const buildHandleInputChange = (
  component: React.Component<AppProps>
): ((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void) => {
  /**
   * Generic method to handle input changes. The _name_ of the input element *must* correspond
   * to the name of the state property that is being changed.
   */
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ): void => {
    const target = e.target;
    const name = target.name;

    if (target instanceof HTMLInputElement) {
      if (target.type === 'checkbox') {
        // Checkboxes don't have target.value, but target.checked instead.
        component.setState<never>({ [name]: target.checked });
      } else {
        // We can simply use target.value.
        component.setState<never>({ [name]: target.value });
      }
    } else {
      // Target must be HTMLSelectElement or HTMLTextAreaElement.
      component.setState<never>({ [name]: target.value });
    }
  };
  return handleInputChange;
};
