import { faArrowAltCircleDown } from '@fortawesome/free-solid-svg-icons';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from 'react-loader-spinner';
import { buildHandleInputChange } from '../util/InputChangeHandler';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { AppProps } from '../App';
import { VehicleProfile } from '../AppState';
import { apiFetch } from '../util/APIFetcher';

export default class DashboardScreen extends React.Component<RouteComponentProps & AppProps> {
  state = {
    vehicle_search: '',
    vehicleTpe: '',
    imei: '',
    seaborneId: '',
    vehicleName: '',
    hardware_field_disabled: true,
    link_button_disabled: true,
    hardware_imei: '',
    hardware_serial_number: '',
    hardware_type: '',
    hardware_iccid: '',
    iotName: '',
  };

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
    const target = e.target;
    const value = target.type === 'checkbox' && 'checked' in target ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  searchVehicleClick = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();

    apiFetch(
      '/api/v1.0/admin/vehicle/search?key=' + this.state.vehicle_search,
      'GET',
      null,
      this.props.appState.userToken
    )
      .then((res) => {
        if (res['status'] === 'FAILURE') {
          this.setState({ hardware_field_disabled: true, vehicleTpe: '', imei: '', seaborneId: '' });
          alert(res['message']);
        } else {
          this.setState({
            vehicleTpe: res['data']['vehicle_tpe'],
            imei: res['data']['imei'],
            seaborneId: res['data']['seaborne_id'],
            hardware_field_disabled: false,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  linkVehicleClick = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();

    apiFetch(
      '/api/v1.0/admin/vehicle/link',
      'POST',
      {
        old_imei: this.state.imei,
        new_imei: this.state.hardware_imei,
        hardware_type: this.state.hardware_type,
        hardware_iccid: this.state.hardware_iccid,
        hardware_serial_number: this.state.hardware_serial_number,
        iot_name: this.state.iotName,
      },
      this.props.appState.userToken
    )
      .then((res) => {
        if (res['status'] === 'FAILURE') {
          alert(res['message']);
        } else {
          this.setState({
            vehicleTpe: res['data']['vehicle_tpe'],
            imei: res['data']['imei'],
            seaborneId: res['data']['seaborne_id'],
            hardware_field_disabled: false,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  render(): React.ReactElement {
    return (
      <OverlayScrollbarsComponent style={{ height: '100%' }} options={{ scrollbars: { autoHide: 'move' } }}>
        <div className="vehicle-overview-component">
          <div>
            <h3>Link a new IoT Device to an existing Vehicle </h3>
            <p>
              Use this form to link a new IoT Device to an existing vehicle. The new IoT Device will be added to the
              IoTHardware db and the vehicle will be updated with the new IoT Device.
            </p>
          </div>

          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <form>
                <div className="mb-3">
                  <label htmlFor="inputImei" className="form-label">
                    Search for Vehicle by ID / Seaborne ID / Name
                  </label>
                  <div className="input-group">
                    <input
                      type="email"
                      className="form-control"
                      id="input_vehicle_search"
                      name="vehicle_search"
                      value={this.state.vehicle_search}
                      onChange={this.handleInputChange}
                    />
                    <button onClick={this.searchVehicleClick} className="btn btn-primary ms-3">
                      Search
                    </button>
                  </div>
                </div>

                <div
                  className={`card mb-3 ${
                    this.state.hardware_field_disabled ? '' : 'border-success border-3'
                  } border-3xxl shadow`}
                >
                  <div className="card-header">
                    <strong>Vehicle Details</strong>
                  </div>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">IMEI: {this.state.imei}</li>
                    <li className="list-group-item">Seaborne ID: {this.state.seaborneId}</li>
                    <li className="list-group-item">Vehicle Type: {this.state.vehicleTpe}</li>
                  </ul>
                </div>

                <hr className="border border-dark border-3 opacity-75"></hr>

                <div className="form-floating mb-3">
                  <input
                    disabled={this.state.hardware_field_disabled}
                    type="email"
                    className="form-control"
                    id="input_hardware_imei"
                    name="hardware_imei"
                    value={this.state.hardware_imei}
                    onChange={this.handleInputChange}
                  />
                  <label>Hardware IMEI</label>
                </div>

                <div className="form-floating mb-3">
                  <input
                    disabled={this.state.hardware_field_disabled}
                    type="email"
                    className="form-control"
                    id="input_hardware_iccid"
                    name="hardware_iccid"
                    value={this.state.hardware_iccid}
                    onChange={this.handleInputChange}
                  />
                  <label>Hardware ICCID</label>
                </div>

                <div className="form-floating mb-3">
                  <input
                    disabled={this.state.hardware_field_disabled}
                    type="email"
                    className="form-control"
                    id="input_hardware_serial_number"
                    name="hardware_serial_number"
                    value={this.state.hardware_serial_number}
                    onChange={this.handleInputChange}
                  />
                  <label>Hardware Serial Number</label>
                </div>

                <div className="form-floating mb-3">
                  <select
                    id="selectVehicleTpe"
                    onChange={this.handleInputChange}
                    name="hardware_type"
                    className="form-select"
                    value={this.state.hardware_type}
                    disabled={this.state.hardware_field_disabled}
                  >
                    <option value="NONE">No Hardware</option>
                    <option value="FMC130">FMC 130 (Teltonika)</option>
                    <option value="FMC230">FMC 230 (Teltonika)</option>
                    <option value="FMC640">FMC 640 (Teltonika)</option>
                    <option value="FMC001">FMC 001 (Teltonika)</option>
                    <option value="FMC003">FMC 003 (Teltonika)</option>
                  </select>
                  <label>Hardware Type</label>
                </div>

                <div className="form-floating mb-3">
                  <input
                    disabled={this.state.hardware_field_disabled}
                    type="email"
                    className="form-control"
                    id="input_hardware_iot_name"
                    name="iotName"
                    value={this.state.iotName}
                    onChange={this.handleInputChange}
                  />
                  <label>IoT Name</label>
                </div>

                <div className="d-grid gap-2 col-6 mx-auto mt-3">
                  <button
                    onClick={this.linkVehicleClick}
                    className="btn btn-primary"
                    disabled={this.state.hardware_field_disabled}
                  >
                    Link Hardware to Vehicle
                  </button>
                </div>
              </form>
              <br />
            </div>
          </div>
        </div>
      </OverlayScrollbarsComponent>
    );
  }
}
