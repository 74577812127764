import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { AppProps, ToastableProps } from '../App';
import { VehicleProfile } from '../AppState';
import { apiFetch } from '../util/APIFetcher';
import { isVariableDeclarationList } from 'typescript';
import { negate } from 'lodash';

export default class DashboardScreen extends React.Component<RouteComponentProps & AppProps & ToastableProps> {
  state = {
    imei: '',
    sensor1MAC: '',
    sensor2MAC: '',
    sensor3MAC: '',
    sensor4MAC: '',
    sensor1check: false,
    sensor2check: false,
    sensor3check: false,
    sensor4check: false,
    priority: false,
  };

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
    const target = e.target;
    const value = target.type === 'checkbox' && 'checked' in target ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  addLoadClick = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();

    if (this.state.imei.length == 15) {
      apiFetch(
        '/api/v1.0/admin/vehicle/teltonika_api?imei=' + this.state.imei,
        'GET',
        null,
        this.props.appState.userToken
      ).then((res) => {
        if (res['status'] === 'FAILURE') {
          this.props.toastManager?.addToast('red', 'ERROR', '', res['message'], 8000);
        } else {
          this.props.toastManager?.addToast(
            'green',
            res['status'],
            '',
            'Activity Status: ' + res['data']['activity_status'],
            8000
          );
        }
      });
    } else this.props.toastManager?.addToast('red', 'ERROR', '', 'Wrong IMEI format', 8000);
  };

  addConfigClick = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();

    // compose message from current state
    let message = '';
    if (this.state.sensor1check) {
      if (this.state.sensor1MAC == '') {
        return this.props.toastManager?.addToast('red', 'ERROR', '', 'MAC1 empty', 8000);
      }
      message += '1200:3;1202:';
      message += this.state.sensor1MAC;
      message +=
        ';14000:FF;14001:FF;14002:FF;14003:FF;14004:FF;14005:FF;14010:3;14011:4;14012:6;14013:7;14014:9;14015:12;14020:1;14021:2;14022:1;14023:2;14024:3;14025:1;14030:1;14031:1;14032:1;14033:1;14034:1;14035:1;14040:4;14041:1;14042:3;14043:8;14044:9;14045:2;14050:00;14060:00;14051:00;14061:00;50530:1;';
    }
    if (this.state.sensor2check) {
      if (this.state.sensor2MAC == '') {
        return this.props.toastManager?.addToast('red', 'ERROR', '', 'MAC2 empty', 8000);
      }
      message += '1250:3;1252:';
      message += this.state.sensor2MAC;
      message +=
        ';14200:FF;14201:FF;14202:FF;14203:FF;14204:FF;14205:FF;14210:3;14211:4;14212:6;14213:7;14214:9;14215:12;14220:1;14221:2;14222:1;14223:2;14224:3;14225:1;14230:1;14231:1;14232:1;14233:1;14234:1;14235:1;14240:4;14241:1;14242:3;14243:8;14244:9;14245:2;14250:00;14260:00;14251:00;14261:00;50540:1;';
    }
    if (this.state.sensor3check) {
      if (this.state.sensor3MAC == '') {
        return this.props.toastManager?.addToast('red', 'ERROR', '', 'MAC3 empty', 8000);
      }
      message += '1300:3;1302:';
      message += this.state.sensor3MAC;
      message +=
        ';14400:FF;14401:FF;14402:FF;14403:FF;14404:FF;14405:FF;14410:3;14411:4;14412:6;14413:7;14414:9;14415:12;14420:1;14421:2;14422:1;14423:2;14424:3;14425:1;14430:1;14431:1;14432:1;14433:1;14434:1;14435:1;14440:4;14441:1;14442:3;14443:8;14444:9;14445:2;14450:00;14460:00;14451:00;14461:00;50550:1;';
    }
    if (this.state.sensor4check) {
      if (this.state.sensor4MAC == '') {
        return this.props.toastManager?.addToast('red', 'ERROR', '', 'MAC4 empty', 8000);
      }
      message += '1350:3;1352:';
      message += this.state.sensor4MAC;
      message +=
        ';14600:FF;14601:FF;14602:FF;14603:FF;14604:FF;14605:FF;14610:3;14611:4;14612:6;14613:7;14614:9;14615:12;14620:1;14621:2;14622:1;14623:2;14624:3;14625:1;14630:1;14631:1;14632:1;14633:1;14634:1;14635:1;14640:4;14641:1;14642:3;14643:8;14644:9;14645:2;14650:00;14660:00;14651:00;14661:00;50560:1;';
    }

    const data = {
      mac1: this.state.sensor1check ? this.state.sensor1MAC : '',
      mac2: this.state.sensor2check ? this.state.sensor2MAC : '',
      mac3: this.state.sensor3check ? this.state.sensor3MAC : '',
      mac4: this.state.sensor4check ? this.state.sensor4MAC : '',
      imei: this.state.imei,
      command: message,
      priority: this.state.priority ? 'HIGH' : 'LOW',
    };

    apiFetch('/api/v1.0/admin/vehicle/addtemp', 'POST', data, this.props.appState.userToken).then((res) => {
      if (res['status'] === 'FAILURE') {
        this.props.toastManager?.addToast('red', 'ERROR', '', res['message'], 8000);
      } else {
        this.props.toastManager?.addToast('green', res['status'], '', res['data']['response'], 8000);
      }
      this.setState({
        imei: '',
        sensor1MAC: '',
        sensor2MAC: '',
        sensor3MAC: '',
        sensor4MAC: '',
        sensor1check: false,
        sensor2check: false,
        sensor3check: false,
        sensor4check: false,
      });
    });
  };

  render(): React.ReactElement {
    return (
      <OverlayScrollbarsComponent style={{ height: '100%' }} options={{ scrollbars: { autoHide: 'move' } }}>
        <div className="vehicle-overview-component">
          <div>
            <h3>Add Bluetooth Temperature Sensors to a already configured Teltonika Device</h3>
            <p>Use this form to add up to 4 Bluetooth Sensors to a Teltonika device configuration</p>
          </div>

          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <form>
                <div className="mb-3">
                  <label htmlFor="inputImei" className="form-label">
                    IMEI
                  </label>
                  <div className="input-group">
                    <input
                      type="email"
                      className="form-control me-3"
                      id="inputImei"
                      name="imei"
                      value={this.state.imei}
                      onChange={this.handleInputChange}
                    />
                    <div className="input-group-append">
                      <button onClick={this.addLoadClick} className="btn btn-primary" type="button">
                        Check Acitvity
                      </button>
                    </div>
                  </div>
                </div>

                <div className="mb-3">
                  <label htmlFor="inputImei" className="form-label">
                    Sensor 1 MAC
                  </label>
                  <div className="input-group">
                    <input
                      type="email"
                      className="form-control me-3"
                      id="inputsensor1MAC"
                      name="sensor1MAC"
                      value={this.state.sensor1MAC}
                      onChange={this.handleInputChange}
                    />
                    <div className="input-group-append">
                      <div className="mb-3 form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="inputsensor1check"
                          name="sensor1check"
                          onChange={this.handleInputChange}
                          checked={this.state.sensor1check}
                        />
                        <label className="form-check-label" htmlFor="inputsensor1check">
                          Select
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-3">
                  <label htmlFor="inputImei" className="form-label">
                    Sensor 2 MAC
                  </label>
                  <div className="input-group">
                    <input
                      type="email"
                      className="form-control me-3"
                      id="inputsensor2MAC"
                      name="sensor2MAC"
                      value={this.state.sensor2MAC}
                      onChange={this.handleInputChange}
                    />
                    <div className="input-group-append">
                      <div className="mb-3 form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="inputsensor2check"
                          name="sensor2check"
                          onChange={this.handleInputChange}
                          checked={this.state.sensor2check}
                        />
                        <label className="form-check-label" htmlFor="inputsensor2check">
                          Select
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-3">
                  <label htmlFor="inputImei" className="form-label">
                    Sensor 3 MAC
                  </label>
                  <div className="input-group">
                    <input
                      type="email"
                      className="form-control me-3"
                      id="inputsensor3MAC"
                      name="sensor3MAC"
                      value={this.state.sensor3MAC}
                      onChange={this.handleInputChange}
                    />
                    <div className="input-group-append">
                      <div className="mb-3 form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="inputsensor3check"
                          name="sensor3check"
                          onChange={this.handleInputChange}
                          checked={this.state.sensor3check}
                        />
                        <label className="form-check-label" htmlFor="inputsensor3check">
                          Select
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-3">
                  <label htmlFor="inputImei" className="form-label">
                    Sensor 4 MAC
                  </label>
                  <div className="input-group">
                    <input
                      type="email"
                      className="form-control me-3"
                      id="inputsensor4MAC"
                      name="sensor4MAC"
                      value={this.state.sensor4MAC}
                      onChange={this.handleInputChange}
                    />
                    <div className="input-group-append">
                      <div className="mb-3 form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="inputsensor4check"
                          name="sensor4check"
                          onChange={this.handleInputChange}
                          checked={this.state.sensor4check}
                        />
                        <label className="form-check-label" htmlFor="inputsensor4check">
                          Select
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div style={{ margin: '10px 0' }}></div>
                <div className="mb-3 form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="prioritycheck"
                    name="priority"
                    onChange={this.handleInputChange}
                    checked={this.state.priority}
                  />
                  <label className="form-check-label" htmlFor="prioritycheck">
                    Send via SMS
                  </label>
                </div>
                <button onClick={this.addConfigClick} className="btn btn-primary">
                  Send Command {this.state.priority ? 'via SMS' : ''}
                </button>
              </form>
              <br />
            </div>
          </div>
        </div>
      </OverlayScrollbarsComponent>
    );
  }
}
