import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import 'animate.css/animate.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'leaflet/dist/leaflet.css';
import 'nprogress/nprogress.css';
import 'overlayscrollbars/css/OverlayScrollbars.css';
import React, { useEffect, useRef, useState } from 'react';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { Route, RouteComponentProps, BrowserRouter as Router, Switch } from 'react-router-dom';
import './App.scss';
import { AppState } from './AppState';
import logo from './assets/ctechnology_logo_sm.png';
import { AppServerComm, AppServerCommMain } from './communication/AppServerComm';
import AddTempScreen from './components/AddTempScreen';
import AddVehicleScreen from './components/AddVehicleScreen';
import CheckTripsScreen from './components/CheckTripsScreen';
import DashboardScreen from './components/DashboardScreen';
import { GeofenceCopyScreen } from './components/GeofenceCopyScreen';
import LoginScreen from './components/LoginScreen';
import MonitorProcessingScreen from './components/MonitorProcessingScreen';
import PipelineAdminScreen from './components/PipelineAdminScreen';
import SubscriptionsOverviewScreen from './components/SubscriptionsOverviewScreen';
import VehicleDetailScreen from './components/VehicleDetailScreen';
import VehicleOverviewScreen from './components/VehicleOverviewScreen';
import VehicleReassignScreen from './components/VehicleReassignScreen';
import ToastManager from './components/elements/ToastManager';
import Menubar from './components/navigation/Menubar';
import { AppDesigner } from './components/screens/design/AppDesigner';
import { InvoiceOverviewScreen } from './components/screens/invoice/InvoiceOverviewScreen';
import './lib/customScroll.css';
import { apiFetch } from './util/APIFetcher';

export type AppProps = {
  appState: AppState;
  appServerComm: AppServerComm;
  setAppState: (newState: Partial<AppState>) => void;
};

export type ToastableProps = {
  toastManager: ToastManager | null;
};

const appServerComm = new AppServerCommMain();

const openInNewTab = (url: string): void => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

const onClickUrl =
  (url: string): (() => void) =>
  () =>
    openInNewTab(url);

const LinkedButton = ({ url, title }: { url: string; title: string }) => {
  return (
    <button className="btn btn-primary" onClick={onClickUrl(url)} style={{ marginRight: 4 }}>
      {title}
    </button>
  );
};

const LogoutButton = ({ onLogoutClick }: { onLogoutClick: () => void }) => {
  return (
    <button className="btn btn-dark" onClick={onLogoutClick}>
      Logout
    </button>
  );
};

const App = () => {
  const [state, setState] = useState<AppState>({
    userToken: localStorage.getItem('UserToken') || undefined,
    ui: {
      activeVehicleId: undefined,
    },
    showMenubar: true,
    vehicleProfiles: [],
    vehiclePipelineLogSummaries: [],
  });

  const queryClient = new QueryClient();
  const routerRef = useRef<Router | null>(null);
  const toastManagerRef = useRef<ToastManager | null>(null);

  const setAppState = (newState: Partial<AppState>): void => {
    setState((prevState) => ({ ...prevState, ...newState }));
  };

  const logout = (): void => {
    apiFetch('/api/v1.0/auth/ops/logout', 'POST', null, state.userToken)
      .catch((_) => _)
      .finally(() => {
        setState({ ...state, userToken: undefined });
        localStorage.removeItem('UserToken');
        if (routerRef.current) {
          (routerRef.current as any).history.push('login');
        }
      });
  };

  useEffect(() => {
    if (localStorage.getItem('UserToken')) {
      setState({
        ...state,
        userToken: localStorage.getItem('UserToken') ?? undefined,
      });
    }
  }, []);

  useEffect(() => {
    if (state.userToken !== undefined) {
      appServerComm
        .getAllVehicleProfiles(localStorage.getItem('UserToken') ?? undefined)
        .then((vehicleProfiles) => {
          setState({ ...state, vehicleProfiles });
        })
        .catch((_) => _);
    }
  }, [state.userToken]);

  return (
    <QueryClientProvider client={queryClient}>
      <Router ref={(el) => (routerRef.current = el)}>
        <div className="App">
          {state.userToken && (
            <header className="App-header">
              <div className="App-header-container">
                <div className="left">
                  <span
                    style={{ fontSize: '0.85rem', margin: 12, marginLeft: 0, marginRight: 16, cursor: 'pointer' }}
                    onClick={() => setAppState({ showMenubar: !state.showMenubar })}
                  >
                    <FontAwesomeIcon icon={faBars}></FontAwesomeIcon>
                  </span>
                  <img
                    src={logo}
                    className="main-pic"
                    alt="logo"
                    style={{ height: 24, marginBottom: -5, cursor: 'pointer' }}
                    onClick={() => (window.location.href = '/vehicle/overview')}
                  />
                  <span
                    onClick={() => (window.location.href = '/vehicle/overview')}
                    style={{ fontSize: '1rem', marginLeft: 15, cursor: 'pointer' }}
                  >
                    Admin&nbsp;Dashboard
                  </span>
                </div>
                <div className="right">
                  <LinkedButton url="https://clickahoy.com/admin/" title="DjangoAdmin" />
                  <LinkedButton url="https://logbook.ctechnology.io/grafana" title="Grafana" />
                  <LinkedButton url="https://bugsbunny.ctechnology.io" title="GlitchTip" />
                  <LinkedButton url="https://github.com/c-technology/clickahoy-seaborne" title="GitHub" />
                  <LogoutButton onLogoutClick={logout} />
                </div>
              </div>
            </header>
          )}

          {state.userToken && state.showMenubar && toastManagerRef.current && (
            <Menubar
              appServerComm={appServerComm}
              appState={state}
              setAppState={setAppState}
              toastManager={toastManagerRef.current}
            />
          )}

          <div
            className="App-content"
            style={{
              left: state.userToken && state.showMenubar ? '280px' : 0,
              top: state.userToken ? 48 : 0,
              height: state.userToken ? 'calc(100% - 48px)' : '100%',
            }}
          >
            <Switch>
              <Switch>
                <Route
                  path="/login"
                  render={(props: RouteComponentProps) => (
                    <LoginScreen
                      setAppState={setAppState}
                      appState={state}
                      appServerComm={appServerComm}
                      {...props}
                    ></LoginScreen>
                  )}
                ></Route>
                <Route
                  path="/vehicle/overview"
                  render={(props: RouteComponentProps) => (
                    <VehicleOverviewScreen
                      setAppState={setAppState}
                      appState={state}
                      appServerComm={appServerComm}
                      {...props}
                    ></VehicleOverviewScreen>
                  )}
                ></Route>
                <Route
                  path="/vehicle/reassign"
                  render={(props: RouteComponentProps) => (
                    <VehicleReassignScreen
                      setAppState={setAppState}
                      appState={state}
                      appServerComm={appServerComm}
                      {...props}
                    ></VehicleReassignScreen>
                  )}
                ></Route>
                <Route
                  path="/vehicle/addTempSensors"
                  render={(props: RouteComponentProps) => (
                    <AddTempScreen
                      setAppState={setAppState}
                      appState={state}
                      appServerComm={appServerComm}
                      toastManager={toastManagerRef.current}
                      {...props}
                    ></AddTempScreen>
                  )}
                ></Route>
                <Route
                  path="/vehicle/add"
                  render={(props: RouteComponentProps) => (
                    <AddVehicleScreen
                      setAppState={setAppState}
                      appState={state}
                      appServerComm={appServerComm}
                      toastManager={toastManagerRef.current}
                      {...props}
                    ></AddVehicleScreen>
                  )}
                ></Route>
                <Route
                  path="/vehicle/detail/:id"
                  render={(props: RouteComponentProps<{ id: string }>) => (
                    <VehicleDetailScreen
                      setAppState={setAppState}
                      appState={state}
                      appServerComm={appServerComm}
                      {...props}
                    ></VehicleDetailScreen>
                  )}
                ></Route>
                <Route
                  path="/processing/monitor"
                  render={(props: RouteComponentProps) => (
                    <MonitorProcessingScreen
                      setAppState={setAppState}
                      appState={state}
                      appServerComm={appServerComm}
                      {...props}
                    ></MonitorProcessingScreen>
                  )}
                ></Route>
                <Route
                  path="/processing/settings"
                  render={(props: RouteComponentProps) => (
                    <PipelineAdminScreen
                      setAppState={setAppState}
                      appState={state}
                      appServerComm={appServerComm}
                      {...props}
                    ></PipelineAdminScreen>
                  )}
                ></Route>
                <Route
                  path="/geofence/copy"
                  render={(props: RouteComponentProps) => (
                    <GeofenceCopyScreen
                      setAppState={setAppState}
                      appState={state}
                      appServerComm={appServerComm}
                      toastManager={toastManagerRef.current}
                      {...props}
                    ></GeofenceCopyScreen>
                  )}
                ></Route>
                <Route
                  path="/trip/check"
                  render={(props: RouteComponentProps) => (
                    <CheckTripsScreen
                      setAppState={setAppState}
                      appState={state}
                      appServerComm={appServerComm}
                      {...props}
                    ></CheckTripsScreen>
                  )}
                ></Route>
                <Route
                  path="/subscription/overview"
                  render={(props: RouteComponentProps) => (
                    <SubscriptionsOverviewScreen
                      setAppState={setAppState}
                      appState={state}
                      appServerComm={appServerComm}
                      toastManager={toastManagerRef.current}
                      {...props}
                    ></SubscriptionsOverviewScreen>
                  )}
                ></Route>
                <Route
                  path="/invoice/overview"
                  render={(props: RouteComponentProps) => (
                    <InvoiceOverviewScreen
                      setAppState={setAppState}
                      appState={state}
                      appServerComm={appServerComm}
                      toastManager={toastManagerRef.current}
                      {...props}
                    ></InvoiceOverviewScreen>
                  )}
                ></Route>
                <Route
                  path="/app/designer"
                  render={(props: RouteComponentProps) => (
                    <AppDesigner
                      setAppState={setAppState}
                      appState={state}
                      appServerComm={appServerComm}
                      toastManager={toastManagerRef.current}
                      {...props}
                    />
                  )}
                ></Route>
                <Route
                  path="/"
                  render={(props: RouteComponentProps) => (
                    <DashboardScreen
                      setAppState={setAppState}
                      appState={state}
                      appServerComm={appServerComm}
                      {...props}
                    ></DashboardScreen>
                  )}
                ></Route>
              </Switch>
            </Switch>
          </div>

          <ToastManager
            ref={(el: ToastManager | null) => (toastManagerRef.current = el)}
            appServerComm={appServerComm}
            setAppState={setAppState}
            appState={state}
          />
        </div>
      </Router>
    </QueryClientProvider>
  );
};

export default App;
