import { Switch as AntdSwitch, SwitchProps as AntdSwitchProps } from 'antd/lib';
import React from 'react';

export const Switch = (
  props: AntdSwitchProps & {
    label: string;
    onChange?: (checked: boolean) => void;
  }
): React.ReactElement => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        gap: '0.5rem',
        margin: '0.5rem 0.5rem -0.5rem 0.5rem',
        padding: '0.5rem 0.5rem -0.5rem 0.5rem',
        borderRadius: '0.5rem',
        cursor: 'pointer',
        width: '100%',
      }}
      onClick={() => props.onChange?.(!props.checked)}
    >
      <span style={{ fontSize: '0.8rem', opacity: 0.75 }}>{props.label}</span>
      <AntdSwitch {...props} />
    </div>
  );
};
