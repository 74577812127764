/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/ban-types */

/* eslint-disable @typescript-eslint/no-empty-function */
/** Use this empty function to prevent the linter "no empty funcs allowed" error. */
export const emptyFunc = () => {};

/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Tries to execute a function and returns its result or the string 'NA'.
 *
 * @param fn The function to execute.
 * @returns Either the function return value or 'NA'.
 */
export function tryOrNA<T>(fn: (...args: any[]) => T): T | 'NA' {
  try {
    return fn();
  } catch {
    return 'NA';
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Tries to execute a function an returns its result, or executes another function and
 * returns that result.
 */
export function tryOr<T, U>(fn: (...args: any[]) => T, orFn: (...args: any[]) => U): T | U {
  try {
    return fn();
  } catch {
    return orFn();
  }
}

/**
 * Converts a value to null, if it is undefined.
 *
 * @param value The value to convert.
 * @returns null if the value is undefined, the value itself otherwise.
 */
export function undefinedAsNull<T>(value: T | undefined): T | null {
  if (value === undefined) {
    return null;
  } else {
    return value;
  }
}

/**
 * Converts a value to undefined, if it is null.
 *
 * @param value The value to convert.
 * @returns undefined if the value is null, the value itself otherwise.
 */
export function nullAsUndefined<T>(value: T | null): T | undefined {
  if (value === null) {
    return undefined;
  } else {
    return value;
  }
}

export const createUUID = () => {
  // @ts-ignore: Mix of operators, but works correctly.
  return ([1e7].toString() + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c: any) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );
};

export const roundTo = (num: number, dec: number) => {
  return Math.round(num * Math.pow(10, dec)) / Math.pow(10, dec);
};

/**
 * Creates a new date that is a number of days ago.
 *
 * @param initialDate
 * @param numDays
 */
export const dateXDaysAgo = (initialDate: Date, numDays: number) => {
  const copiedDate = new Date(initialDate.getTime());
  copiedDate.setDate(initialDate.getDate() - numDays);
  return copiedDate;
};
