import { LatLng, LatLngBounds, Map as LeafletMap } from 'leaflet';
import { DateTime } from 'luxon';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { default as React } from 'react';
import { AttributionControl, MapContainer, Polyline, TileLayer } from 'react-leaflet';
import { RouteComponentProps } from 'react-router-dom';
import { AppProps } from '../App';
import { apiFetch } from '../util/APIFetcher';
import { dateAtMidnight, dateXDaysAgo } from '../util/DateFuncs';
import { dateToDMYHHMM, dateToHHMM, prepareDateForHTMLDatetimeInput } from '../util/Formatters';
import { emptyFunc, roundTo } from '../util/Funcs';
import { buildHandleInputChange } from '../util/InputChangeHandler';
import { CSVLink } from 'react-csv';

type Trip = {
  id: string;
  vehicle_id: string;
  start_timestamp: Date;
  end_timestamp: Date;
  distance_tot: number;
  duration_tot: number;
};

type CheckTripsScreenState = {
  filterVehicle: string;
  filterFrom: string;
  filterTo: string;
  trips: Trip[];
  selectedTrip?: Trip;
  tripPath: { latitude: number; longitude: number; timestamp: string }[];
  deletedTrips: Set<string>;
};

export default class CheckTripsScreen extends React.Component<RouteComponentProps & AppProps, CheckTripsScreenState> {
  state: CheckTripsScreenState = {
    filterVehicle: '',
    filterFrom: prepareDateForHTMLDatetimeInput(dateXDaysAgo(dateAtMidnight(new Date()), -1)),
    filterTo: prepareDateForHTMLDatetimeInput(dateAtMidnight(new Date())),
    trips: [],
    selectedTrip: undefined,
    tripPath: [],
    deletedTrips: new Set(),
  };
  map?: LeafletMap;
  handleInputChange = buildHandleInputChange(this);

  selectTrip = (trip: Trip): void => {
    apiFetch(`/api/v1.0/admin/trip/${trip.id}/path`, 'GET', null, this.props.appState.userToken).then(
      (res) => res['data'] && this.setState({ tripPath: res['data']['boat_route'] })
    );
    this.setState({ selectedTrip: trip });
  };

  deleteSelectedTrip = (): void => {
    if (this.state.selectedTrip) {
      const willDelete = !this.state.deletedTrips.has(this.state.selectedTrip?.id);
      apiFetch(
        `/api/v1.0/admin/trip/${this.state.selectedTrip?.id}`,
        'PUT',
        {
          deleted: willDelete,
        },
        this.props.appState.userToken
      ).then(() => {
        const deletedTrips = this.state.deletedTrips;
        if (this.state.selectedTrip) {
          if (willDelete) {
            deletedTrips.add(this.state.selectedTrip?.id);
          } else {
            deletedTrips.delete(this.state.selectedTrip?.id);
          }
        }
        this.setState({ deletedTrips: deletedTrips });
      });
    }
  };

  handleKeyPress = (e: KeyboardEvent): void => {
    if (e.key === 'Delete') {
      this.deleteSelectedTrip();
    } else if (e.key === 'ArrowDown') {
      const trips = this.state.trips.sort(
        (a, b) => new Date(a.end_timestamp).getTime() - new Date(b.end_timestamp).getTime()
      );
      const selectedTrip = trips.findIndex((trip) => trip.id === this.state.selectedTrip?.id);
      if (selectedTrip < trips.length - 1) {
        this.selectTrip(trips[selectedTrip + 1]);
      }
    } else if (e.key === 'ArrowUp') {
      const trips = this.state.trips.sort(
        (a, b) => new Date(a.end_timestamp).getTime() - new Date(b.end_timestamp).getTime()
      );
      const selectedTrip = trips.findIndex((trip) => trip.id === this.state.selectedTrip?.id);
      if (selectedTrip > 0) {
        this.selectTrip(trips[selectedTrip - 1]);
      }
    }
  };

  refresh = (): void => {
    const fromDatetimeQuery = DateTime.fromJSDate(new Date(this.state.filterFrom)).toUTC().toISO();
    const toDatetimeQuery = DateTime.fromJSDate(new Date(this.state.filterTo)).toUTC().toISO();

    const vehicleFilter = this.state.filterVehicle.length > 0 ? `&vehicle=${this.state.filterVehicle}` : '';
    apiFetch(
      `/api/v1.0/admin/trip/?from=${fromDatetimeQuery}&to=${toDatetimeQuery}${vehicleFilter}`,
      'GET',
      null,
      this.props.appState.userToken
    ).then((res) => res['data'] && this.setState({ trips: res['data'] }));
  };

  componentDidMount(): void {
    this.refresh();
    window.addEventListener('keydown', this.handleKeyPress);
  }

  componentWillUnmount(): void {
    window.removeEventListener('keydown', this.handleKeyPress);
  }

  render(): React.ReactElement {
    const tripPoints: [number, number][] = this.state.tripPath.map((pt) => [pt['latitude'], pt['longitude']]);
    try {
      const tripBounds = new LatLngBounds(tripPoints);
      this.map?.fitBounds(tripBounds);
    } catch (error) {
      emptyFunc();
    }

    const csvDataHeader = [
      { label: 'timestamp', key: 'timestamp' },
      { label: 'longitude', key: 'longitude' },
      { label: 'latitude', key: 'latitude' },
    ];
    let csvFileName = 'undefined.csv';
    let csvData: any[] = [];
    if (this.state.selectedTrip) {
      csvData = this.state.tripPath.map((pt) => {
        return { timestamp: pt['timestamp'], longitude: pt['longitude'], latitude: pt['latitude'] };
      });
      csvFileName = `vehicle_${this.state.selectedTrip.vehicle_id}_trip_${this.state.selectedTrip.id}.csv`;
    }

    return (
      <div className="vehicle-overview-component" style={{ display: 'flex', flexDirection: 'column' }}>
        <div>
          <h3>Check trips</h3>
          <p>
            Use this to quickly go through all recent trips, or trips of an individual vehicle in order to filter out
            unlikely ones, etc. Use up / down and delete to quickly navigate.
          </p>
        </div>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
          <div
            style={{
              width: 480,
              padding: '12px 12px 0px 0px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div>
              <div className="row mb-3">
                <div className="col-6">
                  <label htmlFor="filterFrom" className="form-label">
                    From (date/time)
                  </label>
                  <input
                    type="datetime-local"
                    className="form-control"
                    id="filterFrom"
                    name="filterFrom"
                    value={this.state.filterFrom}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="col-6">
                  <label htmlFor="filterTo" className="form-label">
                    To (date/time)
                  </label>
                  <input
                    type="datetime-local"
                    className="form-control"
                    id="filterTo"
                    name="filterTo"
                    value={this.state.filterTo}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-12">
                  <label htmlFor="filterVehicle" className="form-label">
                    Filter vehicle (by id / name)
                  </label>
                  <div style={{ display: 'flex' }}>
                    <input
                      type="email"
                      className="form-control"
                      id="filterVehicle"
                      name="filterVehicle"
                      value={this.state.filterVehicle}
                      onChange={this.handleInputChange}
                      style={{ flex: 1 }}
                    />
                    <div className="btn btn-primary" style={{ marginLeft: 12 }} onClick={this.refresh}>
                      Go
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <h5>Trips</h5>
            </div>

            <div style={{ flex: 1 }}>
              <OverlayScrollbarsComponent style={{ height: '100%' }} options={{ scrollbars: { autoHide: 'move' } }}>
                {this.state.trips
                  .sort((a, b) => new Date(a.end_timestamp).getTime() - new Date(b.end_timestamp).getTime())
                  .map((trip) => {
                    const vehicle = this.props.appState.vehicleProfiles.find(
                      (vehicle) => vehicle.id.toString() === trip.vehicle_id.toString()
                    );
                    const textDecoration = this.state.deletedTrips.has(trip.id) ? 'line-through' : 'none';
                    return (
                      <div
                        key={trip.id}
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.selectTrip(trip)}
                        className={
                          this.state.deletedTrips.has(trip.id)
                            ? 'striped-background'
                            : this.state.selectedTrip?.id === trip.id
                            ? 'selected-striped-background'
                            : ''
                        }
                      >
                        <div>
                          <b style={{ textDecoration: textDecoration }}>{vehicle?.name}</b>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                          <div>
                            {dateToDMYHHMM(new Date(trip.start_timestamp))} - {dateToHHMM(new Date(trip.end_timestamp))}
                          </div>
                          <div>
                            {roundTo(trip.distance_tot / 1000, 1)} km, {roundTo(trip.duration_tot / 3600, 1)} h
                          </div>
                        </div>
                        <hr />
                      </div>
                    );
                  })}
              </OverlayScrollbarsComponent>
            </div>
          </div>
          <div
            style={{
              flex: 1,
              padding: '12px 0px 0px 12px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div className="row">
              <div className="col-2">
                Distance <br />
                {this.state.selectedTrip && roundTo(this.state.selectedTrip?.distance_tot / 1000, 3)} km
              </div>
              <div className="col-2">
                Duration <br />
                {this.state.selectedTrip && roundTo(this.state.selectedTrip?.duration_tot / 3600, 2)} h
              </div>
              <div className="col-2">
                Num updates <br />
                {this.state.tripPath.length}
              </div>
              <div className="col-1">
                <div className="btn btn-danger" onClick={this.deleteSelectedTrip}>
                  Delete
                </div>
              </div>
              <div className="col-1">
                {csvData.length > 2 && (
                  <CSVLink className="btn btn-primary" filename={csvFileName} data={csvData} headers={csvDataHeader}>
                    CSV
                  </CSVLink>
                )}
              </div>
            </div>

            <div style={{ flex: 1 }}>
              <MapContainer
                className="ctechnology-map"
                center={new LatLng(47.376888, 8.541694)}
                zoom={13}
                whenCreated={(map) => (this.map = map)}
                attributionControl={false}
                style={{ width: '100%', height: '100%' }}
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {/* <MapBoxGLLayer mapStyle="mapbox://styles/dominik-bucher/cke8i57rl52e519oglhaa71yp" /> */}
                <AttributionControl
                  prefix={`© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>`}
                />
                <Polyline color="rgba(255, 255, 255, 0.8)" positions={tripPoints} weight={8}></Polyline>
                <Polyline color={'rgb(73, 120, 188)'} positions={tripPoints} weight={4}></Polyline>
              </MapContainer>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
