import { LogStatementRaw, ModuleInOutput } from '../../AppState';
import Property from './Property';
import { Socket } from './Socket';

export enum ModuleOutcomeState {
  NotExecuted = 'NOT-EXECUTED',
  Success = 'SUCCESS',
  Failure = 'FAILURE',
}

/** A single module of a pipeline (component that is responsible for processing). */
export class Module {
  name: string;
  displayName: string;

  inputSockets: Socket[];
  properties: Property[];
  outputSockets: Socket[];

  outcomeState: string;
  logStatements: LogStatementRaw[];
  moduleInputs: ModuleInOutput;
  moduleOutputs: ModuleInOutput;

  position: {
    x: number;
    y: number;
  };

  constructor(
    name: string,
    x: number,
    y: number,
    inputSockets: Socket[],
    properties: Property[],
    outputSockets: Socket[],
    outcomeState: string,
    logStatements: LogStatementRaw[],
    moduleInputs: ModuleInOutput,
    moduleOutputs: ModuleInOutput
  ) {
    this.name = name;
    this.displayName = name.split('-')[0];

    this.inputSockets = inputSockets;
    this.properties = properties;
    this.outputSockets = outputSockets;

    this.outcomeState = outcomeState;
    this.logStatements = logStatements;
    this.moduleInputs = moduleInputs;
    this.moduleOutputs = moduleOutputs;

    // x and y coordinates of this module (when rendered onto a canvas).
    this.position = { x: x, y: y };
  }
}
