import * as H from 'history';
import NProgress from 'nprogress';
import { AppState } from '../AppState';
import { emptyFunc } from './Funcs';
import serverUrl from './Urls';

export const checkLoggedIn = (
  fnSetAppState: (newState: Partial<AppState>) => void,
  history: H.History,
  onLogin: () => void = emptyFunc
): void => {
  const userToken = localStorage.getItem('UserToken');
  console.log(userToken);

  if (userToken && userToken !== '') {
    NProgress.start();
    window
      .fetch(serverUrl + '/api/v1.0/me', {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: 'Token ' + userToken },
        mode: 'cors',
        credentials: 'include',
      })
      .then((res) => {
        if (res.status !== 200) {
          fnSetAppState({
            userToken: undefined,
          });
          localStorage.removeItem('UserToken');
          throw new Error('Not logged in.');
        } else {
          return res.json();
        }
      })
      .then(() => {
        fnSetAppState({
          userToken: userToken,
        });

        if (history.location.pathname === '/login') {
          history.push('/');
        }
        onLogin();
        NProgress.done();
      })
      .catch((_) => {
        history.push('/login');
        NProgress.done();
      });
  } else {
    history.push('/login');
  }
};
