/**
 * Renders a date as YYYY-MM-DD HH:mm.
 */
export const dateToYMDHHMM = (date: Date): string => {
  try {
    const d = date.getDate();
    const m = date.getMonth() + 1; //Month from 0 to 11
    const y = date.getFullYear();
    const h = date.getHours();
    const min = date.getMinutes();
    return (
      '' +
      y +
      '-' +
      (m <= 9 ? '0' + m : m) +
      '-' +
      (d <= 9 ? '0' + d : d) +
      ' ' +
      (h <= 9 ? '0' + h : h) +
      ':' +
      (min <= 9 ? '0' + min : min)
    );
  } catch {
    return 'NA';
  }
};

const months: { [id: number]: string } = {
  1: 'Jan.',
  2: 'Feb.',
  3: 'Mar.',
  4: 'Apr.',
  5: 'Mai',
  6: 'Jun.',
  7: 'Jul.',
  8: 'Aug.',
  9: 'Sep.',
  10: 'Oct.',
  11: 'Nov.',
  12: 'Dec.',
};

/**
 * Renders a date as DD. MM YYYY HH:mm.
 */
export const dateToDMYHHMM = (date: Date): string => {
  try {
    const d = date.getDate();
    const m = date.getMonth() + 1; //Month from 0 to 11
    const y = date.getFullYear();
    const h = date.getHours();
    const min = date.getMinutes();
    return `${d}. ${months[m]} ${y} ${h <= 9 ? '0' + h : h}:${min <= 9 ? '0' + min : min}`;
  } catch {
    return 'NA';
  }
};

/**
 * Renders a date as HH:mm.
 */
export const dateToHHMM = (date: Date): string => {
  try {
    const h = date.getHours();
    const min = date.getMinutes();
    return `${h <= 9 ? '0' + h : h}:${min <= 9 ? '0' + min : min}`;
  } catch {
    return 'NA';
  }
};

/**
 * Puts a date into a format compatible with the HTML Datetime input.
 *
 * @param date
 */
export const prepareDateForHTMLDatetimeInput = (date: Date): string => {
  const isoStr = new Date(date.getTime()).toISOString();
  return isoStr.substring(0, isoStr.length - 1);
};

/** Formats a date string as a locale date string. */
export const dateToTimestamp = (dateString: string): string => {
  const date = new Date(dateString).toLocaleDateString('de-DE');
  return date;
};
