import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import React, { useCallback, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { AppProps } from '../App';
import { apiFetch } from '../util/APIFetcher';
import './styles/VehicleOverviewScreen.scss';
import { useQuery, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ReactPaginate from 'react-paginate';
import './styles/Pagination.scss';

type VehicleOverviewScreenProps = RouteComponentProps<{ id: string }> & AppProps;

type vehicleProfile = {
  id: number;
  name: string;
  tpe: string;
  seaborne_id: string;
};

const VehicleOverviewScreen = (props: VehicleOverviewScreenProps) => {
  const queryClient = new QueryClient();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(25);
  const [searchKey, setSearchKey] = useState<string>('');
  const history = useHistory();
  const userToken = props.appState.userToken;

  const selectVehicle = useCallback(
    (vehicleId: number): void => {
      history.push(`/vehicle/detail/${vehicleId}`);
    },
    [history]
  );

  const fetchVehicles = async ({ queryKey }: { queryKey: [number, number, string] }) => {
    const [page, itemsPerPage, searchKey] = queryKey;
    const queryParams = new URLSearchParams({
      page: page.toString(),
      itemsPerPage: itemsPerPage.toString(),
      searchKey: searchKey.toString(),
    });

    const res = await apiFetch(`/api/v2.0/admin/vehicle/?${queryParams.toString()}`, 'GET', null, userToken);
    const results = res.data.results;
    const totalItems = res.data.totalItems || 0;

    return { results, totalItems };
  };

  const {
    data = { results: [], totalItems: 0 },
    isLoading,
    isError,
  } = useQuery({
    queryKey: [currentPage, itemsPerPage, searchKey],
    queryFn: fetchVehicles,
    keepPreviousData: true,
  });

  if (isLoading) return <p>Loading...</p>;
  if (isError) return <p>Error fetching posts</p>;

  const pageCount = Math.ceil(data.totalItems / itemsPerPage);

  const handlePageClick = (event: { selected: number }) => {
    setCurrentPage(event.selected + 1);
  };

  const handleItemsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = parseInt(event.target.value, 10);
    setItemsPerPage(selectedValue);
    setCurrentPage(1); // Reset to page 1 whenever items per page changes
  };

  const displayedVehicles = data.results.map((vehicle: vehicleProfile) => {
    return (
      <tr
        key={vehicle.id}
        style={{ cursor: 'pointer' }}
        onClick={() => selectVehicle(vehicle.id)}
        onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#f0f8ff')}
        onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '')}
      >
        <td>{vehicle.id}</td>
        <td>
          {vehicle.name}
          <span style={{ paddingLeft: 4, fontSize: 10, opacity: 0.4 }}>{vehicle.tpe}</span>
        </td>
        <td>{vehicle.seaborne_id}</td>
      </tr>
    );
  });

  return (
    <QueryClientProvider client={queryClient}>
      <OverlayScrollbarsComponent style={{ height: '100%' }} options={{ scrollbars: { autoHide: 'move' } }}>
        <div className="vehicle-overview-component">
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'baseline',
              }}
            >
              <h3>Vehicles on c.technology platform</h3>
            </div>
            <p>Overview of all Vehicles. Search for a specific vehicle and click on it to see Status/Update Data.</p>
            <div className="row mb-3">
              <div className="col-3">
                <input
                  type="text"
                  placeholder="Search ..."
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchKey(e.target.value)}
                  className="search-input"
                />
              </div>
              <div className="col-1">
                <select
                  className="form-select"
                  name="item-selected"
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                >
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
            </div>

            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Vehicle Name</th>
                  <th scope="col">Seaborne ID</th>
                </tr>
              </thead>
              <tbody>{displayedVehicles}</tbody>
            </table>
            {/* Render pagination buttons */}
            <ReactPaginate
              previousLabel={'Previous'}
              nextLabel={'Next'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={pageCount}
              marginPagesDisplayed={2} // How many pages to show at the beginning and end
              pageRangeDisplayed={3} // How many pages to show around the current page
              onPageChange={handlePageClick}
              containerClassName={'pagination'}
              activeClassName={'active'}
            />
          </div>
        </div>
      </OverlayScrollbarsComponent>
    </QueryClientProvider>
  );
};

export default VehicleOverviewScreen;
