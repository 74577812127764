import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { AppProps, ToastableProps } from '../App';
import { VehicleProfile } from '../AppState';
import { apiFetch } from '../util/APIFetcher';

export default class DashboardScreen extends React.Component<RouteComponentProps & AppProps & ToastableProps> {
  state = {
    vehicleTpe: 'BOAT',
    imei: '',
    seaborneId: '',
    iccid: '',
    serialnr: '',
    iotName: '',
    iotRunningId: '',
    vehicleName: '',
    bleTempType: 'NONE',
    canReadout: false,
    obd2Readout: false,
    n2kReadout: false,
    hardwareTpe: 'FMC130',
    protocolTpe: 'TELT_COD_8',
  };

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
    const target = e.target;
    const value = target.type === 'checkbox' && 'checked' in target ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  AutoConfigClick = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();

    apiFetch('/api/v1.0/admin/vehicle/get-device-id', 'GET', null, this.props.appState.userToken)
      .then((res) => {
        if (res['status'] === 'FAILURE') {
          this.props.toastManager?.addToast('red', res['status'], '', res['message'], 8000);
        } else {
          this.setState({ seaborneId: res['data']['seaborne_id'] });
          if (this.state.vehicleTpe === 'BOAT') {
            this.setState({
              iotName: 'clickahoy' + res['data']['seaborne_id'],
              vehicleName: 'clickahoy ' + res['data']['seaborne_id'],
            });
          } else {
            this.setState({
              iotName: 'clickrider' + res['data']['seaborne_id'],
              vehicleName: 'clickrider ' + res['data']['seaborne_id'],
            });
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });

    if (this.state.imei.length == 15) {
      apiFetch(
        '/api/v1.0/admin/vehicle/teltonika_api?imei=' + this.state.imei,
        'GET',
        null,
        this.props.appState.userToken
      )
        .then((res) => {
          if (res['status'] === 'FAILURE') {
            this.props.toastManager?.addToast('red', res['status'], '', res['message'], 8000);
          } else {
            this.setState({ serialnr: res['data']['serial'] });
            if (
              res['data']['model'] == 'FMC130' ||
              res['data']['model'] == 'FMC640' ||
              res['data']['model'] == 'FMC650'
            ) {
              this.setState({
                vehicleTpe: 'BOAT',
                obd2Readout: false,
              });
            } else {
              this.setState({
                vehicleTpe: 'MCYCLE',
                obd2Readout: true,
              });
            }
            if (res['data']['model'] == 'FMC640' || res['data']['model'] == 'FMC650') {
              this.setState({ hardwareTpe: 'FMC640' });
            } else if (res['data']['model'] == 'FMC001' || res['data']['model'] == 'FMC003') {
              this.setState({ hardwareTpe: 'FMC001' });
            } else {
              this.setState({ hardwareTpe: res['data']['model'] });
            }
            if (res['data']['iccid'] > 1) {
              this.setState({ iccid: res['data']['iccid'].slice(0, -1) });
            } else {
              this.setState({ iccid: 'Not yet assigned' });
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      this.props.toastManager?.addToast('red', 'FAILURE', '', 'Wrong IMEI format', 8000);
    }
  };

  addVehicleClick = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();

    const vehicleToAdd = {
      vehicle_tpe: this.state.vehicleTpe,
      imei: this.state.imei,
      ss: this.state.serialnr,
      seaborne_id: this.state.seaborneId,
      iccid: this.state.iccid,
      iot_name: this.state.iotName,
      vehicle_name: this.state.vehicleName,
      ble_temp_type: this.state.bleTempType,
      can_readout: this.state.canReadout,
      obd2_readout: this.state.obd2Readout,
      n2k_readout: this.state.n2kReadout,
      hardware_tpe: this.state.hardwareTpe,
      protocol_tpe: this.state.protocolTpe,
    };

    // Send vehicle to backend.
    apiFetch('/api/v1.0/admin/vehicle/', 'POST', vehicleToAdd, this.props.appState.userToken)
      .then((res) => {
        if (res['status'] === 'FAILURE') {
          alert(res['message']);
        } else {
          this.props.appServerComm
            .getAllVehicleProfiles(localStorage.getItem('UserToken') ?? undefined)
            .then((vehicleProfiles) => {
              this.props.setAppState({ vehicleProfiles: vehicleProfiles });
            })
            .then(() => {
              this.setState({
                vehicleTpe: 'BOAT',
                imei: '',
                seaborneId: '',
                iccid: '',
                serialnr: '',
                iotName: '',
                iotRunningId: '',
                vehicleName: '',
                bleTempType: 'NONE',
                canReadout: false,
                obd2Readout: false,
                n2kReadout: false,
                hardwareTpe: 'FMC130',
                protocolTpe: 'TELT_COD_8',
              });
              this.props.toastManager?.addToast('green', res['status'], '', 'Vehicle succesfully saved', 8000);
            })
            .catch((_) => _);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  render(): React.ReactElement {
    return (
      <OverlayScrollbarsComponent style={{ height: '100%' }} options={{ scrollbars: { autoHide: 'move' } }}>
        <div className="vehicle-overview-component">
          <div>
            <h3>Add a new vehicle to the c.technology platform</h3>
            <p>
              Use this form when configuring new hardware devices to let the system automatically set up everything
              properly, generate a device ID, etc.
            </p>
            <b>Only use this form to register devices with a 1NCE SIM Card!!</b>
          </div>

          <div className="row mt-3">
            <div className="col-lg-6 col-sm-12">
              <form>
                <div className="mb-3">
                  <label htmlFor="inputImei" className="form-label">
                    IMEI
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="inputImei"
                    name="imei"
                    value={this.state.imei}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="selectVehicleTpe" className="form-label">
                    Vehicle type
                  </label>
                  <select
                    id="selectVehicleTpe"
                    className="form-select"
                    value={this.state.vehicleTpe}
                    onChange={(e) => {
                      this.setState({ vehicleTpe: e.target.value });
                      if (e.target.value === 'BOAT') {
                        this.setState({
                          obd2Readout: false,
                          hardwareTpe: 'FMC130',
                          iotName: 'clickahoy' + this.state.seaborneId,
                          vehicleName: 'clickahoy ' + this.state.seaborneId,
                        });
                      }
                      if (e.target.value === 'MCYCLE') {
                        this.setState({
                          obd2Readout: true,
                          hardwareTpe: 'FMC001',
                          iotName: 'clickrider' + this.state.seaborneId,
                          vehicleName: 'clickrider ' + this.state.seaborneId,
                        });
                      }
                    }}
                  >
                    <option value="BOAT">Boat (BOAT)</option>
                    <option value="MCYCLE">Motorcycle (MCYCLE)</option>
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="inputSeaborneId" className="form-label">
                    Device ID (ex. seaborne ID)
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="inputSeaborneId"
                    name="seaborneId"
                    value={this.state.seaborneId}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="inputIccid" className="form-label">
                    ICCID
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="inputIccid"
                    name="iccid"
                    value={this.state.iccid}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="inputSerialNr" className="form-label">
                    Serial Nr.
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="inputSerialNr"
                    name="serialnr"
                    value={this.state.serialnr}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="inputIotName" className="form-label">
                    IoT name (will be automatically assigned to 1nce SIM)
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputIotName"
                    name="iotName"
                    value={this.state.iotName}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="inputVehicleName" className="form-label">
                    Vehicle name
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="inputVehicleName"
                    name="vehicleName"
                    value={this.state.vehicleName}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="selectbleTempType" className="form-label">
                    Temperature Sensors
                  </label>
                  <select
                    id="selectbleTempType"
                    className="form-select"
                    aria-label="Select vehicle type"
                    value={this.state.bleTempType}
                    onChange={(e) => this.setState({ bleTempType: e.target.value })}
                  >
                    <option value="NONE">0 (NONE)</option>
                    <option value="1000">1 (1000)</option>
                    <option value="1100">2 (1100)</option>
                    <option value="1110">3 (1110)</option>
                    <option value="1111">4 (1111)</option>
                  </select>
                </div>
                <div className="mb-3 form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="canReadout"
                    name="canReadout"
                    onChange={this.handleInputChange}
                    checked={this.state.canReadout}
                  />
                  <label className="form-check-label" htmlFor="canReadout">
                    CAN readout
                  </label>
                </div>
                <div className="mb-3 form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="obd2Readout"
                    name="obd2Readout"
                    onChange={this.handleInputChange}
                    checked={this.state.obd2Readout}
                  />
                  <label className="form-check-label" htmlFor="obd2Readout">
                    OBD II readout
                  </label>
                </div>
                <div className="mb-3 form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="n2kReadout"
                    name="n2kReadout"
                    onChange={this.handleInputChange}
                    checked={this.state.n2kReadout}
                  />
                  <label className="form-check-label" htmlFor="n2kReadout">
                    NMEA2000 readout
                  </label>
                </div>
                <div className="mb-3">
                  <label htmlFor="selectHardwareTpe" className="form-label">
                    Hardware Type
                  </label>
                  <select
                    id="selectHardwareTpe"
                    className="form-select"
                    value={this.state.hardwareTpe}
                    onChange={(e) => {
                      this.setState({ hardwareTpe: e.target.value });
                      if (e.target.value === 'FMC130') {
                        this.setState({ protocolTpe: 'TELT_COD_8' });
                      }
                      if (e.target.value === 'FMC640') {
                        this.setState({ protocolTpe: 'TELT_COD_8_EXT' });
                      }
                      if (e.target.value === 'FMC001') {
                        this.setState({ protocolTpe: 'TELT_COD_8' });
                      }
                    }}
                  >
                    <option value="FMC130">FMC 130 (Teltonika)</option>
                    <option value="FMC230">FMC 230 (Teltonika)</option>
                    <option value="FMC640">FMC 640 (Teltonika)</option>
                    <option value="FMC001">FMC 001 (Teltonika)</option>
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="selectProtocolTpe" className="form-label">
                    Protocol type
                  </label>
                  <select
                    id="selectProtocolTpe"
                    className="form-select"
                    value={this.state.protocolTpe}
                    onChange={(e) => {
                      this.setState({ protocolTpe: e.target.value });
                    }}
                  >
                    <option value="TELT_COD_8">Teltonika Codec 8 (TELT_COD_8)</option>
                    <option value="TELT_COD_8_EXT">Teltonika Codec 8 Extended (TELT_COD_8_EXT)</option>
                  </select>
                </div>
                <button onClick={this.AutoConfigClick} className="btn btn-secondary">
                  Auto Config from IMEI
                </button>
                <div style={{ margin: '10px 0' }}></div>
                <button onClick={this.addVehicleClick} className="btn btn-primary">
                  Add {this.state.vehicleTpe === 'BOAT' ? 'boat' : 'motorcycle'}
                </button>
              </form>
              <br />
            </div>
          </div>
        </div>
      </OverlayScrollbarsComponent>
    );
  }
}
