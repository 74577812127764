import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { AppProps } from '../App';
import { apiFetch } from '../util/APIFetcher';
import './styles/VehicleDetailScreen.scss';
import { useQuery, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ReactPaginate from 'react-paginate';
import { prepareDateForHTMLDatetimeInput } from '../util/Formatters';
import { parseDateStringToUTC, dateAtMidnightUTC, dateAtMidnightXDaysAgoUTC } from '../util/DateFuncs';

type TableComponentProps = {
  data: Array<Record<string, any>>;
  highlighting?: boolean;
};

const TableComponent = ({ data, highlighting = true }: TableComponentProps) => {
  if (data.length === 0) {
    return <p>No data available.</p>;
  }

  const headers = Object.keys(data[0]);
  const rows = data.map((item) => Object.values(item));
  return (
    <table className="table table-striped">
      <thead>
        <tr>
          {headers.map((header) => (
            <th key={header}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, index) => (
          <tr
            key={index}
            {...(highlighting && {
              onMouseEnter: (e) => (e.currentTarget.style.backgroundColor = '#f0f8ff'),
              onMouseLeave: (e) => (e.currentTarget.style.backgroundColor = ''),
            })}
          >
            {row.map((cell, index) => (
              <td key={index}>{String(cell)}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

type VehicleDetailScreenProps = RouteComponentProps<{ id: string }> & AppProps;
const VehicleDetailScreen = (props: VehicleDetailScreenProps) => {
  const queryClient = new QueryClient();
  const userToken = props.appState.userToken;
  const itemsPerPage = 25;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [dataType, setDataType] = useState<string>('status');
  const startDate = dateAtMidnightXDaysAgoUTC(7);
  const endDate = dateAtMidnightUTC();

  const [filterFrom, setFilterFrom] = useState(prepareDateForHTMLDatetimeInput(startDate));
  const [filterTo, setFilterTo] = useState(prepareDateForHTMLDatetimeInput(endDate));

  const fetchVehicleUpdateOrStatus = async ({ queryKey }: { queryKey: [number, number, string, string, string] }) => {
    const [page, itemsPerPage, from, to, dataType] = queryKey;

    const fromUTC = parseDateStringToUTC(from);
    const toUTC = parseDateStringToUTC(to);
    const queryParams = new URLSearchParams({
      page: page.toString(),
      itemsPerPage: itemsPerPage.toString(),
      from: fromUTC,
      to: toUTC,
    });

    const res = await apiFetch(
      `/api/v2.0/admin/vehicle/${props.match.params['id']}/${dataType}/?${queryParams.toString()}`,
      'GET',
      null,
      userToken
    );
    return { results: res.data.results, totalItems: res.data.totalItems || 0 };
  };

  const {
    data = { results: [], totalItems: 0 },
    isLoading,
    isError,
  } = useQuery({
    queryKey: [currentPage, itemsPerPage, filterFrom, filterTo, dataType],
    queryFn: fetchVehicleUpdateOrStatus,
    keepPreviousData: true,
  });

  if (isLoading) return <p>Loading...</p>;
  if (isError) return <p>Error fetching data</p>;

  const pageCount = Math.ceil(data.totalItems / itemsPerPage);

  const handlePageClick = (event: { selected: number }) => {
    setCurrentPage(event.selected + 1);
  };

  const handleFilterFromChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterFrom(e.target.value);
    setCurrentPage(1);
  };

  const handleFilterToChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterTo(e.target.value);
    setCurrentPage(1);
  };

  const selectDataType = (type: string) => {
    setDataType(type);
    setCurrentPage(1);
  };

  const handleToggleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const type = e.target.value === 'update' ? 'update' : 'status';
    selectDataType(type);
  };

  return (
    <QueryClientProvider client={queryClient}>
      <div className="vehicle-detail-component">
        <div className="row mb-3">
          <div className="col-2">
            <label htmlFor="filterFrom" className="form-label">
              From (date/time)
            </label>
            <input
              type="datetime-local"
              className="form-control"
              id="filterFrom"
              name="filterFrom"
              value={filterFrom}
              onChange={handleFilterFromChange}
            />
          </div>
          <div className="col-2">
            <label htmlFor="filterTo" className="form-label">
              To (date/time)
            </label>
            <input
              type="datetime-local"
              className="form-control"
              id="filterTo"
              name="filterTo"
              value={filterTo}
              onChange={handleFilterToChange}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="toggle-switch ">
            <input
              type="radio"
              id="pricing-toggle-update"
              name="toggle"
              value="update"
              checked={dataType === 'update'}
              onChange={handleToggleChange}
            />
            <label className="radio-toggle-switch " htmlFor="pricing-toggle-update">
              Status
            </label>

            <input
              type="radio"
              id="pricing-toggle-status"
              name="toggle"
              value="status"
              checked={dataType === 'status'}
              onChange={handleToggleChange}
            />
            <label className="radio-button" htmlFor="pricing-toggle-status">
              Update
            </label>
          </div>
        </div>
        <div style={{ marginBottom: 12, overflowX: 'auto' }}>
          <TableComponent data={data.results} />
        </div>
        <ReactPaginate
          previousLabel={'Previous'}
          nextLabel={'Next'}
          breakLabel={'...'}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          forcePage={currentPage - 1}
          containerClassName={'pagination'}
          activeClassName={'active'}
        />
      </div>
    </QueryClientProvider>
  );
};

export default VehicleDetailScreen;
