/** The type of a socket. */
export enum SocketType {
  Input = 'SocketType.INPUT',
  Output = 'SocketType.OUTPUT',
}

/** A single socket of a module. Here, links can be plugged in. */
export class Socket {
  name: string;
  displayName: string;
  socketType: SocketType;
  socketDataType: string;

  constructor(name: string, socketType: SocketType, socketDataType: string) {
    this.name = name;
    this.displayName = name.split('-')[1];
    this.socketType = socketType;
    this.socketDataType = socketDataType;
  }
}
