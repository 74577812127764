import { Module } from './Module';
import { Socket } from './Socket';

export enum LinkState {
  None = 'NONE',
  Data = 'DATA',
}

/** A link, representing a connection from an output socket to an input socket. */
export class Link {
  name: string;

  fromModule: Module;
  fromSocket: Socket;
  toModule: Module;
  toSocket: Socket;

  linkState: LinkState;

  constructor(
    name: string,
    fromModule: Module,
    fromSocket: Socket,
    toModule: Module,
    toSocket: Socket,
    linkState: LinkState
  ) {
    this.name = name;
    this.fromModule = fromModule;
    this.fromSocket = fromSocket;
    this.toModule = toModule;
    this.toSocket = toSocket;
    this.linkState = linkState;
  }
}
