import React from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import Tooltip from 'react-bootstrap/Tooltip';
import { RouteComponentProps } from 'react-router-dom';
import { AppProps } from '../App';
import { PipelineLog, PipelineWrap } from '../AppState';
import { apiFetch } from '../util/APIFetcher';
import { prepareDateForHTMLDatetimeInput } from '../util/Formatters';

type PipelineAdminScreenState = {
  pipelineLogs: PipelineLog[];
  activePipelineLog: PipelineLog | undefined;
  pipelineWrap: PipelineWrap | undefined;
  fromDate: string;
};

export default class PipelineAdminScreen extends React.Component<
  RouteComponentProps & AppProps,
  PipelineAdminScreenState
> {
  state: PipelineAdminScreenState = {
    pipelineLogs: [],
    activePipelineLog: undefined,
    pipelineWrap: undefined,
    fromDate: prepareDateForHTMLDatetimeInput(new Date()),
  };

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
    const target = e.target;
    const value = target.type === 'checkbox' && 'checked' in target ? target.checked : target.value;
    const name = target.name;

    this.setState<never>({
      [name]: value,
    });
  };

  onClickRebuildPipeline = (): void => {
    apiFetch('/api/v1.0/admin/ops/update-boat-std-pipeline', 'PUT', null, this.props.appState.userToken);
  };

  render(): React.ReactElement {
    return (
      <div className="vehicle-overview-component">
        <div>
          <h3>Pipeline settings</h3>
          <p>Use this to change important processing pipeline / module parameters, look at the pipeline health, etc.</p>

          <OverlayTrigger
            overlay={
              <Tooltip id="tooltip-disabled" placement="right">
                Reloads the pipeline from the Python file and stores it to the database.
              </Tooltip>
            }
          >
            <button className="btn btn-primary" onClick={this.onClickRebuildPipeline}>
              Rebuild Pipeline
            </button>
          </OverlayTrigger>
        </div>
      </div>
    );
  }
}
