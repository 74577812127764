const origin = window.location.origin;
let serverUrl = '';

if (origin.includes('localhost')) {
  serverUrl = 'http://localhost:8000';
} else if (origin.includes('127.0.0.1')) {
  serverUrl = 'http://127.0.0.1:8000';
} else if (origin.includes('172.105.249.19')) {
  // Test instance
  serverUrl = 'http://172.105.249.19';
} else if (origin.includes('test') && !origin.includes('test2')) {
  serverUrl = 'https://test.clickahoy.io';
} else if (origin.includes('test2')) {
  serverUrl = 'https://test2.clickahoy.io';
} else {
  serverUrl = 'https://clickahoy.com';
}

export const getUrl = (url: string): string => {
  if (!url) {
    return url;
  }

  // We have already an absolute URL
  if (url.toLowerCase().startsWith('http')) {
    return url;
  }

  // Convert relative URL to absolute URL
  if (url.startsWith('/')) {
    return serverUrl + url;
  }

  // If not already returned, we don't know what to do
  return url;
};

export default serverUrl;
