/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  faArrowAltCircleDown,
  faBrush,
  faDrawPolygon,
  faMailBulk,
  faMicrochip,
  faMoneyBillWave,
  faPlusCircle,
  faProjectDiagram,
  faRoute,
  faTemperatureLow,
  faTruckPickup,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import CustomScroll from 'react-custom-scroll';
import { NavLink } from 'react-router-dom';
import { AppProps, ToastableProps } from '../../App';
import './styles/Menubar.scss';

const Menubar: React.FC<AppProps & ToastableProps> = () => {
  return (
    <div className="clickahoy-menubar">
      <CustomScroll heightRelativeToParent="calc(100%)">
        <ul>
          <div style={{ height: '1rem' }}></div>
          <NavLink to="/vehicle/overview" activeClassName="selected">
            <li>
              <FontAwesomeIcon icon={faTruckPickup}></FontAwesomeIcon> Vehicles
            </li>
          </NavLink>
          <NavLink to="/vehicle/add" activeClassName="selected">
            <li>
              <FontAwesomeIcon icon={faPlusCircle}></FontAwesomeIcon> Add vehicles
            </li>
          </NavLink>
          <NavLink to="/vehicle/reassign" activeClassName="selected">
            <li>
              <FontAwesomeIcon icon={faArrowAltCircleDown}></FontAwesomeIcon> Reassign vehicles
            </li>
          </NavLink>
          <NavLink to="/vehicle/addTempSensors" activeClassName="selected">
            <li>
              <FontAwesomeIcon icon={faTemperatureLow}></FontAwesomeIcon> Add Temp. Sensors
            </li>
          </NavLink>
          <NavLink to="/trip/check" activeClassName="selected">
            <li>
              <FontAwesomeIcon icon={faRoute}></FontAwesomeIcon> Check trips
            </li>
          </NavLink>
          <NavLink to="/geofence/copy" activeClassName="selected">
            <li>
              <FontAwesomeIcon icon={faDrawPolygon}></FontAwesomeIcon> Copy geofences
            </li>
          </NavLink>
          <NavLink to="/subscription/overview" activeClassName="selected">
            <li>
              <FontAwesomeIcon icon={faMoneyBillWave}></FontAwesomeIcon> Subscriptions
            </li>
          </NavLink>
          <NavLink to="/invoice/overview" activeClassName="selected">
            <li>
              <FontAwesomeIcon icon={faMailBulk}></FontAwesomeIcon> Invoices (organizations)
            </li>
          </NavLink>
          <NavLink to="/app/designer" activeClassName="selected">
            <li>
              <FontAwesomeIcon icon={faBrush}></FontAwesomeIcon> App designer
            </li>
          </NavLink>
          <NavLink to="/processing/monitor" activeClassName="selected">
            <li>
              <FontAwesomeIcon icon={faMicrochip}></FontAwesomeIcon> Processing pipeline
            </li>
          </NavLink>
          <NavLink to="/processing/settings" activeClassName="selected">
            <li>
              <FontAwesomeIcon icon={faProjectDiagram}></FontAwesomeIcon> Pipeline settings
            </li>
          </NavLink>
        </ul>
      </CustomScroll>
    </div>
  );
};

export default Menubar;
