// We don't really need this component anymore.
/* eslint-disable */
import { fabric } from 'fabric';
import * as PIXI from 'pixi.js';
import { InteractivePointerEvent } from 'pixi.js';
import React, { MutableRefObject } from 'react';
import { PipelineLog, PipelineWrap } from '../AppState';
import { GridElement } from './pipeline-pixi/GridElement';
import { LinkElement } from './pipeline-pixi/LinkElement';
import { ModuleElement } from './pipeline-pixi/ModuleElement';
import { Module, ModuleOutcomeState } from './pipeline/Module';
import { Pipeline } from './pipeline/Pipeline';
import { SocketType } from './pipeline/Socket';
import './styles/PixiPipelineComponent.scss';

/** Various tools that can be used on the pipeline component. */
export enum PixiPipelineComponentTool {
  None,
  PanCanvas,
  MoveModule,
  ConnectSockets,
}

/** Props for the pipeline component. */
type PixiPipelineComponentProps = {
  pipelineWrap: PipelineWrap | undefined;
  pipelineLog: PipelineLog | undefined;
  pipeline: Pipeline | undefined;
  onSelectModule: (module: Module | undefined) => void;
};

/** A component wrapping a canvas on which we can configure pipelines. */
export class PixiPipelineComponent extends React.Component<PixiPipelineComponentProps> {
  state = {
    tool: PixiPipelineComponentTool.None,
    inspectingModule: false,
  };
  // Used for updating the size of the canvas when someone moves/resizes the component.
  canvasContainer: MutableRefObject<HTMLDivElement | null>;
  // The HTML canvas element.
  app: PIXI.Application | null = null;
  interactionManager: PIXI.InteractionManager | null = null;

  moduleContainer: PIXI.Container | null = null;
  linkContainer: PIXI.Container | null = null;

  // The pipeline we're currently looking at.
  modules: { [name: string]: ModuleElement } = {};
  links: { [name: string]: LinkElement } = {};

  tool: PixiPipelineComponentTool = PixiPipelineComponentTool.None;
  lastMousePos: { x: number; y: number } = { x: 0, y: 0 };
  lastClientPos: { x: number; y: number } = { x: 0, y: 0 };
  lastModuleMouseClick: Date = new Date();
  lastStageMouseDown: Date = new Date();
  // Holds the mouse state.
  mouse = {
    x: 0,
    y: 0,
    down: false,
    w: 0,
    delta: new fabric.Point(0, 0),
  };
  mainZoomScaleMax = 1;
  mainZoomScaleMin = 0.1;
  mainPositionOffsetX = 0;
  mainPositionOffsetY = 0;
  mainZoomScale = 0.5;

  // Things required to keep the UI displayed.
  fps: number = 10;
  fpsInterval: number = 1000 / this.fps;
  startTime: number = Date.now();
  now: number = Date.now();
  then: number = Date.now();
  elapsed: number = 0;

  // Things used when the ConnectSockets tool is active.
  startLinkCoords: { x: number; y: number } = { x: 0, y: 0 };
  startLinkPosition: SocketType = SocketType.Output;
  gridElement: GridElement | undefined = undefined;

  // For selecting and inspecting components.
  selectedObject: ModuleElement | null = null;
  selectedObjectInterpPos = { x: 0, y: 0 };

  constructor(props: PixiPipelineComponentProps) {
    super(props);

    this.canvasContainer = React.createRef();
  }

  componentDidMount = () => {
    // Resize the canvas to be the full page (also on resize events).
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions();
  };

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateDimensions);
  };

  componentDidUpdate = (prevProps: PixiPipelineComponentProps) => {
    if (this.props.pipelineWrap && this.props.pipelineLog) {
      if (
        this.props.pipelineWrap.id !== prevProps.pipelineWrap?.id ||
        this.props.pipelineLog.id !== prevProps.pipelineLog?.id
      ) {
        setTimeout(() => {
          if (this.app && this.props.pipeline) {
            this.renderPipeline(this.app, this.props.pipeline);
          }
        }, 200);
      }
    }
  };

  /** Keeps the canvas at the proper size (whole browser window). */
  updateDimensions = () => {
    if (this.canvasContainer.current) {
      if (this.app) {
        this.app.destroy();
      }

      while (this.canvasContainer.current.firstChild) {
        this.canvasContainer.current.removeChild(this.canvasContainer.current.lastChild!);
      }
      this.app = new PIXI.Application({
        width: this.canvasContainer.current.clientWidth,
        height: this.canvasContainer.current.clientHeight,
        antialias: true, // default: false
        transparent: false, // default: false
        resolution: 1, // default: 1
        backgroundColor: 0x2c2c2c,
      });
      this.canvasContainer.current?.appendChild(this.app.view);
      this.app.stage.scale.x = 0.5;
      this.app.stage.scale.y = 0.5;

      this.interactionManager = new PIXI.InteractionManager(this.app.renderer);

      this.app.view.onmousedown = this.onMouseDown;
      this.app.view.onmousemove = this.onMouseMove;
      this.app.view.onmouseup = this.onMouseUp;
      this.app.view.onwheel = this.onMouseWheel;
      this.app.view.onclick = this.onClick;
    }
  };

  // Events on main stage. ====================================================
  onMouseWheel = (ev: WheelEvent) => {
    if (this.app && this.interactionManager) {
      const x = this.interactionManager?.mouse.global.x,
        y = this.interactionManager?.mouse.global.y,
        isZoomIn = ev.deltaY < 0;

      const direction = isZoomIn ? 1 : -1;
      var factor = 1 + direction * 0.3;

      // Set the old scale to be referenced later.
      var oldScale = this.mainZoomScale;

      // Manipulate the scale based on direction.
      this.mainZoomScale = oldScale * factor;

      // Check to see that the scale is not outside of the specified bounds.
      if (this.mainZoomScale > this.mainZoomScaleMax) this.mainZoomScale = this.mainZoomScaleMax;
      else if (this.mainZoomScale < this.mainZoomScaleMin) this.mainZoomScale = this.mainZoomScaleMin;

      this.mainPositionOffsetX = (this.mainPositionOffsetX - x) * (this.mainZoomScale / oldScale) + x;
      this.mainPositionOffsetY = (this.mainPositionOffsetY - y) * (this.mainZoomScale / oldScale) + y;

      this.app.stage.scale.set(this.mainZoomScale, this.mainZoomScale);
      this.app.stage.position.set(this.mainPositionOffsetX, this.mainPositionOffsetY);
    }
  };

  onMouseDown = (ev: MouseEvent) => {
    ev.stopPropagation();

    this.setState({ tool: PixiPipelineComponentTool.PanCanvas });
    this.lastMousePos.x = ev.clientX;
    this.lastMousePos.y = ev.clientY;
    this.lastStageMouseDown = new Date();
  };

  onMouseMove = (ev: MouseEvent) => {
    if (this.app && this.canvasContainer.current) {
      if (this.state.tool === PixiPipelineComponentTool.PanCanvas) {
        const dx = ev.clientX - this.lastMousePos.x;
        const dy = ev.clientY - this.lastMousePos.y;

        this.mainPositionOffsetX += dx;
        this.mainPositionOffsetY += dy;

        this.app.stage.position.set(this.mainPositionOffsetX, this.mainPositionOffsetY);
        this.lastMousePos.x = ev.clientX;
        this.lastMousePos.y = ev.clientY;

        this.gridElement?.update(
          this.canvasContainer.current.clientWidth,
          this.canvasContainer.current?.clientHeight,
          this.mainPositionOffsetX,
          this.mainPositionOffsetY,
          this.mainZoomScale
        );
        return;
      } else if (this.state.tool === PixiPipelineComponentTool.MoveModule && this.selectedObject) {
        const dx = ev.clientX - this.lastMousePos.x;
        const dy = ev.clientY - this.lastMousePos.y;

        this.selectedObjectInterpPos.x += dx / this.mainZoomScale;
        this.selectedObjectInterpPos.y += dy / this.mainZoomScale;

        this.selectedObject.moduleContainer.position.x = Math.round(this.selectedObjectInterpPos.x / 100) * 100;
        this.selectedObject.moduleContainer.position.y = Math.round(this.selectedObjectInterpPos.y / 100) * 100;

        this.selectedObject.module.position.x = this.selectedObject.moduleContainer.position.x;
        this.selectedObject.module.position.y = this.selectedObject.moduleContainer.position.y;
      }
    }
    this.lastMousePos.x = ev.clientX;
    this.lastMousePos.y = ev.clientY;
  };

  onMouseUp = (ev: MouseEvent) => {
    this.setState({ tool: PixiPipelineComponentTool.None });
    if (!this.state.inspectingModule) {
      if (this.app && this.props.pipeline) {
        this.renderLinks(this.app, this.props.pipeline);
      }
      this.selectedObject = null;
    }
  };

  onClick = () => {
    // Only do this if a) this is not also a click on a module and b) if it's a real click, i.e.,
    // the time between mouse down and click is short enough.
    if (
      Math.abs(new Date().getTime() - this.lastModuleMouseClick.getTime()) > 200 &&
      Math.abs(new Date().getTime() - this.lastStageMouseDown.getTime()) < 200
    ) {
      this.selectedObject = null;
      for (let otherModule of Object.values(this.modules)) {
        otherModule.moduleContainer.alpha =
          otherModule.module.outcomeState === ModuleOutcomeState.NotExecuted ? 0.3 : 1;
      }
      for (let link of Object.values(this.links)) {
        let l = link as LinkElement;
        l.linkGraphics.alpha = l.defaultAlpha;
      }
      this.props.onSelectModule(undefined);
      this.setState({ inspectingModule: false });
    }
  };

  // Events on modules. =======================================================
  onModuleOver = (module: ModuleElement) => {
    if (!this.state.inspectingModule) {
      for (let otherModule of Object.values(this.modules)) {
        if (otherModule !== module) {
          (otherModule as ModuleElement).moduleContainer.alpha = 0.2;
        }
      }
      for (let link of Object.values(this.links)) {
        let l = link as LinkElement;
        if (!module.linksConnected.includes(l.link.name)) {
          l.linkGraphics.alpha = l.hidingAlpha;
        }
      }
    }
  };

  onModuleOut = (module: ModuleElement) => {
    if (!this.state.inspectingModule) {
      for (let otherModule of Object.values(this.modules)) {
        otherModule.moduleContainer.alpha =
          otherModule.module.outcomeState === ModuleOutcomeState.NotExecuted ? 0.3 : 1;
      }
      for (let link of Object.values(this.links)) {
        let l = link as LinkElement;
        l.linkGraphics.alpha = l.defaultAlpha;
      }
    }
  };

  onModuleMouseDown = (module: ModuleElement) => {
    this.setState({ tool: PixiPipelineComponentTool.MoveModule });
    if (this.app && this.linkContainer) {
      while (this.linkContainer.children[0]) {
        this.linkContainer.children[0].destroy();
      }
      this.linkContainer.destroy();
    }
    this.selectedObject = module;
    this.selectedObjectInterpPos = { x: module.moduleContainer.position.x, y: module.moduleContainer.position.y };
  };

  onModuleMouseUp = (module: ModuleElement) => {
    if (Math.abs(new Date().getTime() - this.lastModuleMouseClick.getTime()) > 200) {
      this.setState({ tool: PixiPipelineComponentTool.None });
      if (this.app && this.props.pipeline) {
        this.renderLinks(this.app, this.props.pipeline);
      }
      this.selectedObject = null;
    }
  };

  onModuleMouseMove = (ev: InteractivePointerEvent, module: ModuleElement) => {
    this.onMouseMove(ev as MouseEvent);
  };

  onModuleClick = (ev: InteractivePointerEvent, module: ModuleElement) => {
    ev.stopPropagation();
    ev.preventDefault();

    this.selectedObject = module;
    for (let otherModule of Object.values(this.modules)) {
      if (otherModule !== module) {
        (otherModule as ModuleElement).moduleContainer.alpha = 0.2;
      }
    }
    for (let link of Object.values(this.links)) {
      let l = link as LinkElement;
      if (!module.linksConnected.includes(l.link.name)) {
        l.linkGraphics.alpha = l.hidingAlpha;
      }
    }
    this.props.onSelectModule(module.module);
    this.lastModuleMouseClick = new Date();
    this.setState({ inspectingModule: true });
  };

  renderLinks = (app: PIXI.Application, pipeline: Pipeline) => {
    if (this.linkContainer) {
      this.linkContainer.destroy();
    }
    this.linkContainer = new PIXI.Container();
    for (let link of pipeline.links) {
      let l = new LinkElement(link, this.modules);
      this.links[link.name] = l;
      l.render(this.linkContainer);
    }
    if (this.moduleContainer) {
      app.stage.removeChild(this.moduleContainer);
      app.stage.addChild(this.linkContainer);
      app.stage.addChild(this.moduleContainer);
    }
  };

  renderPipeline = (app: PIXI.Application, pipeline: Pipeline) => {
    this.gridElement = new GridElement(
      this.canvasContainer.current?.clientWidth ?? 2000,
      this.canvasContainer.current?.clientHeight ?? 2000
    );
    this.gridElement.render(app.stage);

    // Add modules.
    this.moduleContainer = new PIXI.Container();
    for (let module of pipeline.modules) {
      let m = new ModuleElement(module);
      this.modules[module.name] = m;
      m.render(
        this.moduleContainer,
        this.onModuleOver,
        this.onModuleOut,
        this.onModuleMouseDown,
        this.onModuleMouseUp,
        this.onModuleMouseMove,
        this.onModuleClick
      );
    }
    // Add links.
    this.renderLinks(app, pipeline);
    app.stage.addChild(this.moduleContainer);
  };

  render() {
    return <div ref={this.canvasContainer} className="canvas-container"></div>;
  }
}
