import {
  LinkRaw,
  LogStatementRaw,
  ModuleInOutput,
  ModuleRaw,
  ModuleState,
  PipelineLog,
  PipelineLogRaw,
  PipelineRaw,
  PipelineVersion,
  PipelineVizRaw,
  PipelineWrap,
  SocketRaw,
  VehicleProfile,
} from '../AppState';
import { apiFetch } from '../util/APIFetcher';

/**
 * Defines the communication between the c.technology Admin App and the
 * backend (API).
 */
export interface AppServerComm {
  getAllVehicleProfiles: (token?: string) => Promise<VehicleProfile[]>;

  getVehiclePipelineLogSummaries: (token?: string) => Promise<PipelineLog[]>;
  getVehiclePipelineLogs: (vehicleId: string, token?: string) => Promise<PipelineLog[]>;
  getPipelineWrap: (pipelineName: string, pipelineVersion: string, token?: string) => Promise<PipelineWrap>;

  reprocessVehicle: (vehicleId: string, from: string, token?: string) => Promise<void>;
  processDailyVehicle: (vehicleId: string, date: string, token?: string) => Promise<void>;
  processDailyVehicleUntilNow: (vehicleId: string, date: string, token?: string) => Promise<void>;
}

/**
 * Main app server communication with the real backend.
 */
export class AppServerCommMain implements AppServerComm {
  /** Takes data from the backend and transforms it into the structure used in the app. */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  _vehicleProfileFromJson = (vehicleData: any): VehicleProfile => {
    return {
      id: vehicleData['id'],
      vehicleTpe: vehicleData['vehicle_tpe'],

      name: vehicleData['name'],
      tpe: vehicleData['tpe'],
      desc: vehicleData['desc'],
      imageId: vehicleData['image_id'],
      imageUrl: vehicleData['image_url'],

      iotName: vehicleData['iot_name'],

      ain1Type: vehicleData['ain_1_type'],
      ain2Type: vehicleData['ain_2_type'],
      din1Type: vehicleData['din_1_type'],
      bleTempType: vehicleData['ble_temp_type'],
      batteryModel: vehicleData['battery_model'],
      deviceId: vehicleData['seaborne_id'],

      canReadout: vehicleData['can_readout'],
      hibernationMode: vehicleData['hibernation_mode'],
    };
  };

  /** Gets all vehicles that a user has access to (but not their current status). */
  getAllVehicleProfiles = async (token?: string | undefined): Promise<VehicleProfile[]> => {
    return await apiFetch('/api/v1.0/admin/vehicle/', 'GET', null, token).then((res) => {
      const vehicleDatas = res['data'];
      const vehicleProfiles = [];
      for (const vehicleData of vehicleDatas) {
        vehicleProfiles.push(this._vehicleProfileFromJson(vehicleData));
      }
      return vehicleProfiles;
    });
  };

  /** Gets the most recent pipeline log for each vehicle from the backend. */
  getVehiclePipelineLogSummaries = async (token?: string): Promise<PipelineLog[]> => {
    return await apiFetch('/api/v1.0/admin/vehicle/pipeline-log-summary/', 'GET', null, token).then((res) => {
      const pipelineLogDatas = res['data'];
      const pipelineLogs = [];
      for (const pipelineLogData of pipelineLogDatas) {
        pipelineLogs.push({
          id: pipelineLogData['id'],
          name: pipelineLogData['name'],
          version: pipelineLogData['version'],
          timestamp: new Date(pipelineLogData['timestamp']),
          duration: pipelineLogData['duration'],
          vehicleId: pipelineLogData['vehicle'],
          pipelineLog: this._pipelineLogRawFromJson(pipelineLogData['pipeline_log']),
        });
      }
      return pipelineLogs;
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  _logStatementRawFromJson = (data: any): LogStatementRaw => {
    return {
      level: data['level'],
      msg: data['msg'],
    };
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  _moduleInOutputFromJson = (data: any): ModuleInOutput => {
    return data;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  _moduleStateFromJson = (data: any): ModuleState => {
    return {
      outcomeState: data['outcome_state'],
      logStatements: data['log_statements'].map(this._logStatementRawFromJson),
    };
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  _pipelineLogRawFromJson = (data: any): PipelineLogRaw => {
    const moduleInputs: { [id: string]: ModuleInOutput } = {};
    for (const id in data['module_inputs']) {
      moduleInputs[id] = data['module_inputs'][id];
    }
    const moduleOutputs: { [id: string]: ModuleInOutput } = {};
    for (const id in data['module_outputs']) {
      moduleOutputs[id] = data['module_outputs'][id];
    }
    const moduleStates: { [id: string]: ModuleState } = {};
    for (const id in data['module_states']) {
      moduleStates[id] = this._moduleStateFromJson(data['module_states'][id]);
    }

    return {
      pipelineVersion: this._pipelineVersionFromJson(data['pipeline_version']),
      logStatements: data['log_statements'].map(this._logStatementRawFromJson),
      moduleInputs: moduleInputs,
      moduleOutputs: moduleOutputs,
      moduleStates: moduleStates,
    };
  };

  /** Gets pipeline logs from the backend. */
  getVehiclePipelineLogs = async (vehicleId: string, token?: string): Promise<PipelineLog[]> => {
    return await apiFetch('/api/v1.0/admin/vehicle/' + vehicleId + '/pipeline-log/', 'GET', null, token).then((res) => {
      const pipelineLogs = [];
      for (const pipelineLogData of res['data']) {
        pipelineLogs.push({
          id: pipelineLogData['id'],
          name: pipelineLogData['name'],
          version: pipelineLogData['version'],
          timestamp: new Date(pipelineLogData['timestamp']),
          duration: pipelineLogData['duration'],
          vehicleId: pipelineLogData['vehicle'],
          pipelineLog: this._pipelineLogRawFromJson(pipelineLogData['pipeline_log']),
        });
      }
      return pipelineLogs;
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  _pipelineVersionFromJson = (data: any): PipelineVersion => {
    return { name: data[0], version: data[1] };
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  _linkRawFromJson = (data: any): LinkRaw => {
    return {
      name: data['name'],
      fromModule: data['from_module'],
      fromSocket: data['from_socket'],
      toModule: data['to_module'],
      toSocket: data['to_socket'],
    };
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  _socketRawFromJson = (data: any): SocketRaw => {
    return {
      name: data['name'],
      dataType: data['data_type'],
      socketType: data['socket_type'],
    };
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  _moduleRawFromJson = (data: any): ModuleRaw => {
    const inSockets: { [id: string]: SocketRaw } = {};
    for (const id in data['in_sockets']) {
      inSockets[id] = this._socketRawFromJson(data['in_sockets'][id]);
    }
    const outSockets: { [id: string]: SocketRaw } = {};
    for (const id in data['out_sockets']) {
      outSockets[id] = this._socketRawFromJson(data['out_sockets'][id]);
    }
    return {
      name: data['name'],
      inSockets: inSockets,
      outSockets: outSockets,
      properties: data['properties'],
    };
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  _pipelineRawFromJson = (data: any): PipelineRaw => {
    return {
      pipelineVersion: this._pipelineVersionFromJson(data['pipeline_version']),
      links: data['links'].map(this._linkRawFromJson),
      modules: data['modules'].map(this._moduleRawFromJson),
    };
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  _pipelineVizFromJson = (data: any): PipelineVizRaw => {
    const modulePositions: { [id: string]: { x: number; y: number } } = {};
    for (const id in data['module_positions']) {
      modulePositions[id] = { x: data['module_positions'][id]['x'], y: data['module_positions'][id]['y'] };
    }
    return {
      pipelineVersion: this._pipelineVersionFromJson(data['pipeline_version']),
      modulePositions: modulePositions,
    };
  };

  /** Gets a pipeline from the backend. */
  getPipelineWrap = async (pipelineName: string, pipelineVersion: string, token?: string): Promise<PipelineWrap> => {
    return await apiFetch('/api/v1.0/admin/pipeline/' + pipelineName + '/' + pipelineVersion, 'GET', null, token).then(
      (res) => {
        return {
          id: res['data']['id'],
          name: res['data']['name'],
          version: res['data']['version'],
          pipeline: this._pipelineRawFromJson(res['data']['pipeline']),
          pipelineViz: this._pipelineVizFromJson(res['data']['pipeline_viz']),
        };
      }
    );
  };

  /** Reprocesses a vehicle. */
  reprocessVehicle = async (vehicleId: string, from: string, token?: string): Promise<void> => {
    return await apiFetch('/api/v1.0/admin/vehicle/' + vehicleId + '/ops/reprocess?from=' + from, 'PUT', null, token);
  };

  /** Process the daily data for a vehicle. */
  processDailyVehicle = async (vehicleId: string, date: string, token?: string): Promise<void> => {
    return await apiFetch(
      '/api/v1.0/admin/vehicle/' + vehicleId + '/ops/process-daily?date=' + date,
      'PUT',
      null,
      token
    );
  };

  /** Process the daily data for a vehicle from date until now. */
  processDailyVehicleUntilNow = async (vehicleId: string, date: string, token?: string): Promise<void> => {
    return await apiFetch(
      '/api/v1.0/admin/vehicle/' + vehicleId + '/ops/process-daily?date=' + date + '&until=now',
      'PUT',
      null,
      token
    );
  };
}
