import React, { ReactNode } from 'react';
import { Toast } from 'react-bootstrap';
import { AppProps } from '../../App';
import { createUUID } from '../../util/Funcs';
import './styles/ToastManager.scss';

type ToastType = {
  id: string;
  color: string;
  title: string;
  when: string;
  body: string;
  show: boolean;
};

class ToastManager extends React.Component<AppProps> {
  state = {
    toasts: [] as ToastType[],
  };

  hideToast = (id: string): void => {
    const newToasts = this.state.toasts.map((t) => {
      if (t.id === id) {
        t.show = false;
      }
      return t;
    });
    this.setState({ toasts: newToasts });
  };

  addToast = (color: string, title: string, when: string, body: string, timeout: number): void => {
    const newToasts = this.state.toasts;
    const id = createUUID();
    newToasts.push({
      id: id,
      color: color,
      title: title,
      when: when,
      body: body,
      show: true,
    });
    this.setState({ toasts: newToasts });
    setTimeout(() => {
      const newToasts = this.state.toasts.map((t) => {
        if (t.id === id) {
          t.show = false;
        }
        return t;
      });
      this.setState({ toasts: newToasts });
    }, timeout);
  };

  render(): ReactNode {
    const toastRender = this.state.toasts.map((t) => {
      return (
        <Toast key={t.id} show={t.show} onClose={() => this.hideToast(t.id)}>
          <Toast.Header>
            <span style={{ backgroundColor: t.color, width: 12, height: 12 }} className="rounded mr-2" />
            <strong className="mr-auto">{t.title}</strong>
            <small>{t.when}</small>
          </Toast.Header>
          <Toast.Body>{t.body}</Toast.Body>
        </Toast>
      );
    });

    return (
      <div
        aria-live="polite"
        aria-atomic="true"
        style={{ position: 'fixed', top: 60, right: 12, width: '320px', zIndex: 18000 }}
      >
        {toastRender}
      </div>
    );
  }
}

export default ToastManager;
