import serverUrl from './Urls';

/**
 * Performs an anonymous POST request using multi-part form data. This
 * is for example required to register a new user.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const apiPOSTMultipartAnon = async (endpoint: string, body: Record<string, any>): Promise<Response> => {
  const url = serverUrl + endpoint;
  const formData = new FormData();
  for (const name in body) {
    formData.append(name, body[name]);
  }

  const response = await window.fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    body: formData,
    mode: 'cors',
    credentials: 'include',
  });
  return response;
};

/**
 * Fetches something from the API. The requested data *and* the body must be
 * JSON compatible. If a timeout is specified, the call aborts after X ms with a
 * DOMException (named AbortError).
 */
export const apiFetch = async (
  endpoint: string,
  method: string,
  body?: any,
  token?: string,
  timeout?: number,
  expectJson = true
): Promise<any> => {
  const url = serverUrl + endpoint;

  console.log(method + ' ' + url);

  const fetchProps = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors' as RequestMode,
    credentials: 'include' as RequestCredentials,
  } as Record<string, any>;

  if (body) {
    fetchProps['body'] = JSON.stringify(body);
  }

  if (token) {
    fetchProps.headers['Authorization'] = 'Token ' + token;
  }

  if (timeout) {
    const controller = new AbortController();
    setTimeout(() => controller.abort(), timeout);
    fetchProps['signal'] = controller.signal;
  }

  return window.fetch(url, fetchProps).then((res) => {
    if (expectJson && res.headers.get('Content-Type')?.includes('application/json')) {
      return res.json();
    } else {
      return res;
    }
  });
};
