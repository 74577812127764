// We don't really need this component anymore.
/* eslint-disable */
import { ModuleInOutput, PipelineLog, PipelineWrap } from '../../AppState';
import { Link, LinkState } from './Link';
import { Module } from './Module';
import { Socket, SocketType } from './Socket';

/** Encapsulates a pipeline with modules and links. */
export class Pipeline {
  pipelineVersion: { name: string; version: number };
  modules: Module[];
  links: Link[];

  constructor(pipelineVersion: { name: string; version: number }, modules: Module[], links: Link[]) {
    this.pipelineVersion = pipelineVersion;
    this.modules = modules;
    this.links = links;
  }

  /** Serializes a pipeline to JSON. */
  serialize = () => {
    let pipeline = {
      pipeline_version: this.pipelineVersion,
      modules: this.modules.map((module) => {
        let inSockets: any = {};
        module.inputSockets.forEach((socket) => {
          inSockets[socket.name] = {
            name: socket.name,
            socket_type: socket.socketType,
            data_type: socket.socketDataType,
          };
        });
        let outSockets: any = {};
        module.outputSockets.forEach((socket) => {
          outSockets[socket.name] = {
            name: socket.name,
            socket_type: socket.socketType,
            data_type: socket.socketDataType,
          };
        });
        return {
          name: module.name,
          in_sockets: inSockets,
          properties: {},
          out_sockets: outSockets,
        };
      }),
      links: this.links.map((link) => {
        return {
          name: link.name,
          from_module: link.fromModule.name,
          from_socket: link.fromSocket.name,
          to_module: link.toModule.name,
          to_socket: link.toSocket.name,
        };
      }),
    };
    let modulePositions: any = {};
    this.modules.forEach((module) => {
      modulePositions[module.name] = module.position;
    });
    let pipelineViz: any = {
      pipeline_version: this.pipelineVersion,
      module_positions: modulePositions,
    };
    return {
      pipeline: pipeline,
      pipeline_viz: pipelineViz,
    };
  };
}

/** Reads a pipeline from a JSON serialization (e.g., from the Python backend). */
export function preparePipeline(pipelineWrap: PipelineWrap, pipelineLog: PipelineLog): Pipeline {
  let modules = [];
  let links = [];

  let modulePositions: any = {};
  for (let [moduleName, module] of Object.entries(pipelineWrap.pipelineViz.modulePositions)) {
    modulePositions[moduleName] = module;
  }

  let moduleOutcomeStates: any = {};
  for (let [moduleName, module] of Object.entries(pipelineLog.pipelineLog.moduleStates)) {
    moduleOutcomeStates[moduleName] = module.outcomeState;
  }

  let moduleLogStatements: any = {};
  for (let [moduleName, module] of Object.entries(pipelineLog.pipelineLog.moduleStates)) {
    moduleLogStatements[moduleName] = module.logStatements;
  }

  let moduleInputs: { [name: string]: ModuleInOutput } = {};
  for (let [moduleName, module] of Object.entries(pipelineLog.pipelineLog.moduleInputs)) {
    moduleInputs[moduleName] = module;
  }

  let moduleOutputs: { [name: string]: ModuleInOutput } = {};
  for (let [moduleName, module] of Object.entries(pipelineLog.pipelineLog.moduleOutputs)) {
    moduleOutputs[moduleName] = module;
  }

  let i = 0;

  let moduleLookup: any = {};
  let inPlugLookup: any = {};
  let outPlugLookup: any = {};
  for (let module of pipelineWrap.pipeline.modules) {
    let inSockets = [];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (let [_, socket] of Object.entries(module.inSockets)) {
      let p = new Socket(socket.name, SocketType.Input, socket.dataType);
      inSockets.push(p);
      inPlugLookup[socket.name] = p;
    }
    let outSockets = [];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (let [_, socket] of Object.entries(module.outSockets)) {
      let p = new Socket(socket.name, SocketType.Output, socket.dataType);
      outSockets.push(p);
      outPlugLookup[socket.name] = p;
    }

    let modulePosition;
    if (module.name in modulePositions) {
      modulePosition = modulePositions[module.name];
    } else {
      modulePosition = { x: i * 350, y: 100 };
    }

    let moduleOutcomeState;
    if (module.name in moduleOutcomeStates) {
      moduleOutcomeState = moduleOutcomeStates[module.name];
    } else {
      moduleOutcomeState = 'UNKNOWN';
    }
    let moduleLogStatement;
    if (module.name in moduleLogStatements) {
      moduleLogStatement = moduleLogStatements[module.name];
    } else {
      moduleLogStatement = [];
    }

    let moduleInput;
    if (module.name in moduleInputs) {
      moduleInput = moduleInputs[module.name];
    } else {
      moduleInput = [];
    }
    let moduleOutput;
    if (module.name in moduleOutputs) {
      moduleOutput = moduleOutputs[module.name];
    } else {
      moduleOutput = [];
    }

    let newModule = new Module(
      module.name,
      modulePosition.x,
      modulePosition.y,
      inSockets,
      [],
      outSockets,
      moduleOutcomeState,
      moduleLogStatement,
      moduleInput,
      moduleOutput
    );
    modules.push(newModule);
    moduleLookup[module.name] = newModule;
    i += 1;
  }

  for (let link of pipelineWrap.pipeline.links) {
    let outModule = moduleLookup[link.fromModule] as Module;
    let outSocket = outPlugLookup[link.fromSocket] as Socket;
    let inModule = moduleLookup[link.toModule] as Module;
    let inSocket = inPlugLookup[link.toSocket] as Socket;

    let linkState = LinkState.None;
    // TODO: Thanks to JS "logic" this will be false for module outputs such as 0, [], etc., which might be valid.
    if (outModule.moduleOutputs[link.fromSocket.split('-')[1]]) {
      linkState = LinkState.Data;
    }
    let newLink = new Link(link.name, outModule, outSocket, inModule, inSocket, linkState);
    links.push(newLink);
  }
  return new Pipeline(
    {
      name: pipelineWrap.pipeline.pipelineVersion.name,
      version: pipelineWrap.pipeline.pipelineVersion.version,
    },
    modules,
    links
  );
}

/** Creates an exemplary pipeline to be drawn on canvas. */
// export function createExemplaryPipeline(): Pipeline {
//   let modules = [];
//   let links = [];

//   let exPC1ExtVolt = new Socket('External Voltage', SocketType.Input, 'any');
//   let exPC1Temp1 = new Socket('Temperature 1', SocketType.Input, 'any');
//   let exPC1ExtVoltPct = new Socket('External Voltage Pct.', SocketType.Output, 'any');
//   let exPC1TempScaled = new Socket('Temperature Scaled', SocketType.Output, 'any');

//   let exPC2Loc = new Socket('Location (Lon/Lat)', SocketType.Input, 'any');
//   let exPC2DigIn1 = new Socket('Digital Input 1', SocketType.Input, 'any');
//   let exPC2LandConn = new Socket('Landline Connected', SocketType.Output, 'any');
//   let exPC2HarbDet = new Socket('Harbor Detected', SocketType.Output, 'any');
//   let exPC2TempAl = new Socket('Temp. Alarm', SocketType.Output, 'any');

//   let exPC1 = new Module('M1', 100, 100, [exPC1ExtVolt, exPC1Temp1],
//     [new Property('Threshold 1')], [exPC1ExtVoltPct, exPC1TempScaled], 'SUCCESS', [], [], []);
//   modules.push(exPC1);
//   let exPC2 = new Module('M2', 500, 100, [exPC2Loc, exPC2DigIn1],
//     [new Property('Harbor Size')], [exPC2LandConn, exPC2HarbDet, exPC2TempAl], 'FAILURE', [], [], []);
//   modules.push(exPC2);

//   links.push(new Link('exLink', exPC1, exPC1ExtVoltPct, exPC2, exPC2DigIn1));

//   return new Pipeline({ name: 'STD', version: 1 }, modules, links);
// }
