// We don't really need this component anymore.
/* eslint-disable */
import * as PIXI from 'pixi.js';
import { Link, LinkState } from '../pipeline/Link';
import { SocketType } from '../pipeline/Socket';
import { ModuleElement } from './ModuleElement';

export class LinkElement {
  linkGraphics: PIXI.Graphics;
  link: Link;
  modules: { [name: string]: ModuleElement };

  defaultAlpha = 0.25;
  hidingAlpha = 0.05;

  constructor(link: Link, modules: { [name: string]: ModuleElement }) {
    this.linkGraphics = new PIXI.Graphics();
    this.link = link;
    this.modules = modules;
  }

  render(stage: PIXI.Container) {
    // Create connectors. They go below everything else.
    let linkColor = 0x43d400;
    if (this.link.linkState !== LinkState.Data) {
      linkColor = 0xfc5203;
    }
    this.linkGraphics.lineStyle(3, linkColor, 1, undefined, true);
    let fromModule = this.modules[this.link.fromModule.name];
    let fromPosition = fromModule.getSocketPosition(this.link.fromSocket.name, SocketType.Output);
    let toModule = this.modules[this.link.toModule.name];
    let toPosition = toModule.getSocketPosition(this.link.toSocket.name, SocketType.Input);

    fromModule.addConnectedLink(this.link.name);
    toModule.addConnectedLink(this.link.name);

    this.linkGraphics.moveTo(fromPosition.x, fromPosition.y);
    this.linkGraphics.bezierCurveTo(
      fromPosition.x + 40,
      fromPosition.y,
      toPosition.x - 40,
      toPosition.y,
      toPosition.x,
      toPosition.y
    );
    this.linkGraphics.endFill();
    this.linkGraphics.alpha = this.defaultAlpha;
    stage.addChild(this.linkGraphics);
  }
}
