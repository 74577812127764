import React from 'react';
import { Spinner } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router-dom';
import { AppProps } from '../App';
import { checkLoggedIn } from '../util/LoginChecker';
import './styles/ScreenStyles.scss';

export default class DashboardScreen extends React.Component<RouteComponentProps & AppProps> {
  componentDidMount(): void {
    checkLoggedIn(this.props.setAppState, this.props.history, () => {
      setTimeout(() => {
        this.props.history.push('/vehicle/overview');
      }, 750);
    });
  }

  render(): React.ReactElement {
    return (
      <div
        className="dashboard-admin-screen"
        style={{
          placeContent: 'center',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <h3 style={{ marginBottom: '1.8rem' }}>Loading your dashboard</h3>
          <div>
            <Spinner as="span" variant="primary" role="status" aria-hidden="true" animation="grow" />
          </div>
        </div>
      </div>
    );
  }
}
