import { faCheckCircle, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Loader from 'react-loader-spinner';
import { Route, RouteComponentProps } from 'react-router-dom';
import { AppProps, ToastableProps } from '../App';
import { apiFetch } from '../util/APIFetcher';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ButtonGroup } from 'react-bootstrap';

type SubscriptionDataSummary = {
  origin_type: string;
  timestamp_start: string;
  timestamp_end: string;
};

type SubscriptionsOverviewScreenState = {
  subscriptionDataSummaries: { [id: number]: SubscriptionDataSummary };
  loadingDataOf: Set<number>;
  revealedSeaborneIds: Set<number>;
  show: boolean;
  selectedVehicleId: string;
  showInvalidOnly: boolean;
};

function toTimestamp(strDate: string) {
  const date = new Date(strDate).toLocaleDateString('de-DE');
  return date;
}

function checkIfActive(strEndDate: string) {
  const today = new Date();
  const endDate = new Date(Date.parse(strEndDate));
  if (today < endDate) {
    return true;
  } else {
    return false;
  }
}

const SubscriptionTable = (props: AppProps) => {
  const queryClient = useQueryClient();
  const [showModal, setShowModal] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState<any>(null);
  const [showInvalidOnly, setShowInvalidOnly] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const vehicleQuery = useQuery(
    ['vehicles', props.appState.userToken],
    () => apiFetch(`/api/v2.0/admin/vehicle/`, 'GET', null, props.appState.userToken).then((res) => res['data']),
    {
      staleTime: 1000 * 60 * 5, // 5 minutes
    }
  );

  const subscriptionQuery = useQuery(
    ['subscriptions', props.appState.userToken],
    () =>
      apiFetch(`/api/v2.0/admin/subscription-data`, 'GET', null, props.appState.userToken).then((res) => res['data']),
    {
      staleTime: 1000 * 60 * 5, // 5 minutes
    }
  );

  if (vehicleQuery.isLoading || subscriptionQuery.isLoading) {
    return (
      <div
        className="centered"
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
      >
        <Loader type="Oval" color="#00BFFF" height={80} width={80} />
      </div>
    );
  }

  if (vehicleQuery.isError || subscriptionQuery.isError) {
    return (
      <div className="center">
        <p>Error fetching data</p>
      </div>
    );
  }

  const mergedData = vehicleQuery.data.map((vehicle: any) => {
    const matchingSubscriptions = subscriptionQuery.data.filter(
      (subscription: any) => subscription.vehicle === vehicle.id
    );

    return {
      ...vehicle,
      subscriptions: matchingSubscriptions,
    };
  });

  const daysUntilExpiry = (vehicle: any) => {
    const subscriptions = vehicle.subscriptions;

    if (subscriptions.length > 0) {
      const latestSubscription = subscriptions[subscriptions.length - 1];
      const timestampEnd = new Date(latestSubscription.timestamp_end);
      const currentDate = new Date();

      // Calculate the time difference in milliseconds
      const timeDiff = timestampEnd.getTime() - currentDate.getTime();

      // Convert time difference from milliseconds to days
      const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

      return daysDiff;
    }

    return -1;
  };

  const filteredData = mergedData.filter((vehicle: any) => {
    const seaborneIdMatch = vehicle.seaborne_id.toLowerCase().includes(searchTerm.toLowerCase());
    const idMatch = vehicle.id?.toString().toLowerCase().includes(searchTerm);
    const iccidMatch = vehicle.iccid?.toString().toLowerCase().includes(searchTerm);
    const iotNameMatch = vehicle.iot_name.toLowerCase().includes(searchTerm);
    const nameMatch = vehicle.name.toLowerCase().includes(searchTerm);
    const isValid = daysUntilExpiry(vehicle);

    if (showInvalidOnly) {
      return !isValid && (seaborneIdMatch || idMatch || iccidMatch || iotNameMatch || nameMatch);
    }

    return seaborneIdMatch || idMatch || iccidMatch || iotNameMatch || nameMatch;
  });

  const sortedFilteredData = [...filteredData].sort((a, b) => {
    const validityComparison = daysUntilExpiry(a) - daysUntilExpiry(b);

    const idComparison = a.id - b.id;

    return validityComparison == 0 ? idComparison : validityComparison;
  });

  const handleShowModal = (vehicle: any) => {
    if (vehicle.subscriptions.length > 0) {
      setSelectedSubscription(vehicle.subscriptions[vehicle.subscriptions.length - 1]);
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <input type="text" placeholder="Search" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
      </div>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>ID</th>
            <th>Active</th>
            <th>Seaborne ID</th>
            <th>Name</th>
            <th>IoT Name</th>
            <th>Subscription Status</th>
            <th>Subscription Origin</th>
          </tr>
        </thead>
        <tbody>
          {sortedFilteredData.map((vehicle: any) => (
            <tr key={vehicle.id}>
              <td>{vehicle.id}</td>
              <td>
                <FontAwesomeIcon icon={faCheckCircle} color="green" />
              </td>
              <td>{vehicle.seaborne_id}</td>
              <td>{vehicle.name}</td>
              <td>{vehicle.iot_name}</td>
              <td>
                <span
                  style={{
                    padding: '2px 5px',
                    borderRadius: '3px',
                    backgroundColor: daysUntilExpiry(vehicle) > 10 ? '#28a745' : '#dc3545',
                    color: '#fff',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleShowModal(vehicle)}
                >
                  {daysUntilExpiry(vehicle) > 10 ? 'Valid' : 'Invalid'}
                </span>
                <span
                  style={{
                    padding: '2px 5px',
                    borderRadius: '3px',
                  }}
                >
                  {daysUntilExpiry(vehicle) + ' days left'}
                </span>
              </td>
              <td>
                {vehicle.subscriptions.length > 0
                  ? vehicle.subscriptions[vehicle.subscriptions.length - 1].origin_type
                  : ''}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Subscription Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedSubscription && (
            <div>
              <p>
                <strong>Subscription ID:</strong> {selectedSubscription.id}
              </p>
              <p>
                <strong>Activating User:</strong> {selectedSubscription.activating_user}
              </p>
              <p>
                <strong>Timestamp Start:</strong> {selectedSubscription.timestamp_start}
              </p>
              <p>
                <strong>Timestamp End:</strong> {selectedSubscription.timestamp_end}
              </p>
              <p>
                <strong>Service Type:</strong> {selectedSubscription.service_type}
              </p>
              <p>
                <strong>Origin Type:</strong> {selectedSubscription.origin_type}
              </p>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

const InvoiceOverviewScreen = (props: RouteComponentProps & AppProps & ToastableProps): React.ReactElement => {
  return (
    <React.Fragment>
      <OverlayScrollbarsComponent style={{ height: '100%' }} options={{ scrollbars: { autoHide: 'move' } }}>
        <div className="vehicle-overview-component">
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'baseline',
              }}
            >
              <h3>Subscription overview</h3>
            </div>
            <p>Use this to get an overview over running and past subscription on a per vehicle basis</p>
            <div className="vehicle-overview-table">
              <SubscriptionTable {...props} />
            </div>
          </div>
        </div>
      </OverlayScrollbarsComponent>
    </React.Fragment>
  );
};

export default InvoiceOverviewScreen;
