import * as _ from 'lodash';
import React from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import Tooltip from 'react-bootstrap/Tooltip';
import CustomScroll from 'react-custom-scroll';
import { RouteComponentProps } from 'react-router-dom';
import Select from 'react-select';
import { AppProps } from '../App';
import { PipelineLog, PipelineWrap } from '../AppState';
import { dateToYMDHHMM, prepareDateForHTMLDatetimeInput } from '../util/Formatters';
import { roundToDecimals } from '../util/MathPlus';
import { Module, ModuleOutcomeState } from './pipeline/Module';
import { Pipeline, preparePipeline } from './pipeline/Pipeline';
import { PixiPipelineComponent } from './PixiPipelineComponent';

type MonitorProcessingScreenState = {
  pipelineLogs: PipelineLog[];
  pipelineLog: PipelineLog | undefined;
  pipelineWrap: PipelineWrap | undefined;
  pipeline: Pipeline | undefined;
  fromDate: string;
  selectedModule: Module | undefined;
};

export default class MonitorProcessingScreen extends React.Component<
  RouteComponentProps & AppProps,
  MonitorProcessingScreenState
> {
  state: MonitorProcessingScreenState = {
    pipelineLogs: [],
    pipelineLog: undefined,
    pipelineWrap: undefined,
    pipeline: undefined,
    fromDate: prepareDateForHTMLDatetimeInput(new Date()),
    selectedModule: undefined,
  };

  /** Changes the active vehicle. */
  changeVehicle = (value: { value: string; label: string } | null): void => {
    const newVehicleId = value?.value.split('-')[1];

    if (newVehicleId) {
      const newUi = this.props.appState.ui;
      newUi.activeVehicleId = newVehicleId;
      this.props.setAppState({ ui: newUi });
      this.props.appServerComm
        .getVehiclePipelineLogs(newVehicleId, this.props.appState.userToken)
        .then((pipelineLogs) => {
          this.setState({ pipelineLogs: pipelineLogs });
          if (pipelineLogs.length > 0) {
            this.selectPipelineLog(pipelineLogs[0]);
          }
        })
        .catch((e) => console.log(e));
    }
  };

  /** Changes the active pipeline log. */
  changePipelineLog = (value: { value: string; label: string } | null): void => {
    const newPipelineLogId = value?.value.split('-')[1];

    if (newPipelineLogId) {
      const newPipelineLog = _.find(this.state.pipelineLogs, (p) => p.id === parseInt(newPipelineLogId));
      if (newPipelineLog) {
        this.selectPipelineLog(newPipelineLog);
      }
    }
  };

  selectPipelineLog = (pipelineLog: PipelineLog): void => {
    const pipelineLogName = pipelineLog.name;
    const pipelineLogVersion = pipelineLog.version.toString();
    this.props.appServerComm
      .getPipelineWrap(pipelineLogName, pipelineLogVersion, this.props.appState.userToken)
      .then((pipelineWrap) => {
        const pipeline = preparePipeline(pipelineWrap, pipelineLog);
        this.setState({ pipelineWrap: pipelineWrap, pipelineLog: pipelineLog, pipeline: pipeline });
      })
      .catch((e) => console.log(e));
  };

  onClickReprocess = (): void => {
    this.props.appServerComm
      .reprocessVehicle(
        this.props.appState.ui.activeVehicleId ?? '',
        this.state.fromDate,
        this.props.appState.userToken
      )
      .catch((_) => _);
  };

  onClickProcessDaily = (): void => {
    this.props.appServerComm
      .processDailyVehicle(
        this.props.appState.ui.activeVehicleId ?? '',
        this.state.fromDate,
        this.props.appState.userToken
      )
      .catch((_) => _);
  };

  onClickProcessDailyUntilNow = (): void => {
    this.props.appServerComm
      .processDailyVehicleUntilNow(
        this.props.appState.ui.activeVehicleId ?? '',
        this.state.fromDate,
        this.props.appState.userToken
      )
      .catch((_) => _);
  };

  /** Saves the pipeline by uploading it to the server. */
  onClickSavePipeline = (): void => {
    const serializedPipeline = JSON.stringify(this.state.pipeline?.serialize());
    console.log(JSON.parse(serializedPipeline));
    // window.fetch('http://127.0.0.1:8000/update-pipeline',
    //   {
    //     method: 'POST', headers: {
    //       'Accept': 'application/json, text/plain, */*',
    //       'Content-Type': 'application/json'
    //     }, body: serializedPipeline
    //   });
  };

  customStyles = {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
    option: (provided: any, state: any) => ({
      ...provided,
      // color: state.isSelected ? 'black' : 'black',
      color: 'black',
      fontSize: 12,

      ':before': {
        backgroundColor: state.data.color,
        borderRadius: 10,
        content: '" "',
        display: 'inline-block',
        marginRight: 8,
        height: 10,
        width: 10,
      },
    }),
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
    control: (provided: any, state: any) => ({
      ...provided,
      width: state.selectProps.width,
      margin: 4,
      padding: 0,
      fontSize: 12,
    }),
  };

  onSelectModule = (module?: Module): void => {
    this.setState({ selectedModule: module });
  };

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
    const target = e.target;
    const value = target.type === 'checkbox' && 'checked' in target ? target.checked : target.value;
    const name = target.name;

    this.setState<never>({
      [name]: value,
    });
  };

  render(): React.ReactElement {
    const vehicleSelectOptions = this.props.appState.vehicleProfiles
      .sort((p1, p2) => p1.id - p2.id)
      .map((vehicleProfile) => {
        const mostRecentPipelineLog = this.props.appState.vehiclePipelineLogSummaries.find(
          (pl) => pl.vehicleId === vehicleProfile.id
        );

        if (mostRecentPipelineLog) {
          const successfulModules = _.filter(
            mostRecentPipelineLog.pipelineLog.moduleStates,
            (el) => el.outcomeState === ModuleOutcomeState.Success
          ).length;
          const notExecutedModules = _.filter(
            mostRecentPipelineLog.pipelineLog.moduleStates,
            (el) => el.outcomeState === ModuleOutcomeState.NotExecuted
          ).length;
          const erroneousModules = _.filter(
            mostRecentPipelineLog.pipelineLog.moduleStates,
            (el) => el.outcomeState === ModuleOutcomeState.Failure
          ).length;

          const allLogstatements = _.flatMap(mostRecentPipelineLog.pipelineLog.moduleStates, (el) =>
            _.map(el.logStatements, (ls) => ls.level)
          );
          const debugOutputs = _.filter(allLogstatements, (el) => el === 10).length;
          const infoOutputs = _.filter(allLogstatements, (el) => el === 20).length;
          const irregularLogoutputs = _.filter(allLogstatements, (el) => el > 20).length;

          let bubbleColor = 'black';
          if (erroneousModules > 0) {
            bubbleColor = 'red';
          }
          return {
            value: 'vehicle-' + vehicleProfile.id,
            label: `${vehicleProfile.id}: ${vehicleProfile.name} (Modules: 👌🏻${successfulModules}/🤷🏻‍♂️${notExecutedModules}/😵${erroneousModules}, Logs: 👌🏻${debugOutputs}/🤷🏻‍♂️${infoOutputs}/😵${irregularLogoutputs})`,
            color: bubbleColor,
          };
        } else {
          return {
            value: 'vehicle-' + vehicleProfile.id,
            label: `${vehicleProfile.id}: ${vehicleProfile.name} (😴)`,
            color: 'darkgrey',
          };
        }
      });

    const pipelineLogSelectOptions = this.state.pipelineLogs
      .sort((pl1, pl2) => pl1.timestamp.valueOf() - pl2.timestamp.valueOf())
      .map((pipelineLog) => {
        const successfulModules = _.filter(
          pipelineLog.pipelineLog.moduleStates,
          (el) => el.outcomeState === ModuleOutcomeState.Success
        ).length;
        const notExecutedModules = _.filter(
          pipelineLog.pipelineLog.moduleStates,
          (el) => el.outcomeState === ModuleOutcomeState.NotExecuted
        ).length;
        const erroneousModules = _.filter(
          pipelineLog.pipelineLog.moduleStates,
          (el) => el.outcomeState === ModuleOutcomeState.Failure
        ).length;

        const allLogstatements = _.flatMap(pipelineLog.pipelineLog.moduleStates, (el) =>
          _.map(el.logStatements, (ls) => ls.level)
        );
        const debugOutputs = _.filter(allLogstatements, (el) => el === 10).length;
        const infoOutputs = _.filter(allLogstatements, (el) => el === 20).length;
        const irregularLogoutputs = _.filter(allLogstatements, (el) => el > 20).length;

        let bubbleColor = 'black';
        if (erroneousModules > 0) {
          bubbleColor = 'red';
        }
        return {
          value: 'pipelinelog-' + pipelineLog.id,
          label: `${dateToYMDHHMM(pipelineLog.timestamp)} (${pipelineLog.name}/${
            pipelineLog.version
          }): ${roundToDecimals(
            pipelineLog.duration,
            3
          )} s  (Modules: 👌🏻${successfulModules}/🤷🏻‍♂️${notExecutedModules}/😵${erroneousModules}, Logs: 👌🏻${debugOutputs}/🤷🏻‍♂️${infoOutputs}/😵${irregularLogoutputs})`,
          color: bubbleColor,
        };
      });

    const pipelineLogStatements = this.state.pipelineLog?.pipelineLog.logStatements;
    let pipelineLogOutput = (
      <div>
        <h4>Pipeline Output Log</h4>
        {pipelineLogStatements?.map((logStatement, idx) => {
          let color = 'white';
          switch (logStatement.level) {
            case 10:
              color = 'rgba(255, 255, 255, 0.3)';
              break;
            case 20:
              color = 'white';
              break;
            case 30:
              color = 'orange';
              break;
            case 40:
              color = 'red';
              break;
            case 50:
              color = 'darkred';
              break;
            default:
              color = 'rgba(255, 255, 255, 0.3)';
              break;
          }
          return (
            <p
              style={{ color: color, marginBlockStart: 0, marginBlockEnd: 0, fontSize: 10 }}
              key={this.state.pipelineLog?.id + '-' + idx}
            >
              {logStatement['msg']}
            </p>
          );
        })}
      </div>
    );
    if (this.state.selectedModule) {
      pipelineLogOutput = (
        <div>
          <h4>{this.state.selectedModule.displayName}</h4>
          <div style={{ fontSize: 'small', opacity: 0.5 }}>{this.state.selectedModule.name}</div>

          <h6 style={{ marginTop: 24 }}>Module Input Data</h6>
          <div style={{ fontSize: 10, opacity: 0.8 }}>
            {Object.entries(this.state.selectedModule.moduleInputs).map(([name, value]) => (
              <div style={{ wordWrap: 'break-word' }} key={name}>
                <b>{name}</b>: {`${value}`}
              </div>
            ))}
          </div>

          <h6 style={{ marginTop: 24 }}>Module Output Data</h6>
          <div style={{ fontSize: 10, opacity: 0.8 }}>
            {Object.entries(this.state.selectedModule.moduleOutputs).map(([name, value]) => (
              <div style={{ wordWrap: 'break-word' }} key={name}>
                <b>{name}</b>: {`${value}`}
              </div>
            ))}
          </div>

          <h6 style={{ marginTop: 24 }}>Module Output Log</h6>
          {this.state.selectedModule.logStatements?.map((logStatement, idx) => {
            let color = 'white';
            switch (logStatement.level) {
              case 10:
                color = 'rgba(255, 255, 255, 0.3)';
                break;
              case 20:
                color = 'white';
                break;
              case 30:
                color = 'orange';
                break;
              case 40:
                color = 'red';
                break;
              case 50:
                color = 'darkred';
                break;
              default:
                color = 'rgba(255, 255, 255, 0.3)';
                break;
            }
            return (
              <p
                style={{ color: color, marginBlockStart: 0, marginBlockEnd: 0, fontSize: 10, opacity: 0.8 }}
                key={this.state.pipelineLog?.id + '-' + idx}
              >
                {logStatement['msg']}
              </p>
            );
          })}
        </div>
      );
    }

    return (
      <div className="pixi-pipeline-component">
        <div className="pixi-pipeline-component-header">
          <div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Select
                options={vehicleSelectOptions}
                onChange={this.changeVehicle}
                styles={this.customStyles}
                width={640}
              ></Select>
              <Select
                options={pipelineLogSelectOptions}
                onChange={this.changePipelineLog}
                styles={this.customStyles}
                width={720}
              ></Select>
            </div>
          </div>
          <div>
            <input
              type="datetime-local"
              className="form-control"
              id="fromDate"
              name="fromDate"
              value={this.state.fromDate}
              onChange={this.handleInputChange}
              style={{ marginLeft: 4, marginRight: 4, width: 'auto', display: 'inline-block' }}
            ></input>
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled" placement="bottom">
                  Reprocesses all vehicle data from the start of this day.
                </Tooltip>
              }
            >
              <button
                className="btn btn-primary"
                style={{ verticalAlign: 'top', marginRight: 4 }}
                onClick={this.onClickReprocess}
              >
                Reprocess Vehicle
              </button>
            </OverlayTrigger>
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled" placement="bottom">
                  Runs the daily calibration routines, calibrating fuel and harbors.
                </Tooltip>
              }
            >
              <button
                className="btn btn-primary"
                style={{ verticalAlign: 'top', marginRight: 4 }}
                onClick={this.onClickProcessDaily}
              >
                Run Daily Calibration Routine
              </button>
            </OverlayTrigger>
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled" placement="bottom">
                  Runs the daily calibration routines, calibrating fuel and harbors.
                </Tooltip>
              }
            >
              <button
                className="btn btn-primary"
                style={{ verticalAlign: 'top', marginRight: 4 }}
                onClick={this.onClickProcessDailyUntilNow}
              >
                Run Daily Routine for all Days
              </button>
            </OverlayTrigger>
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled" placement="bottom">
                  Prints the pipeline to the console.
                </Tooltip>
              }
            >
              <button className="btn btn-primary" style={{ verticalAlign: 'top' }} onClick={this.onClickSavePipeline}>
                Log Pipeline
              </button>
            </OverlayTrigger>
          </div>
        </div>
        <div className="pixi-pipeline-component-content">
          <PixiPipelineComponent
            pipelineWrap={this.state.pipelineWrap}
            pipelineLog={this.state.pipelineLog}
            pipeline={this.state.pipeline}
            onSelectModule={this.onSelectModule}
          ></PixiPipelineComponent>
          <div className="log-container">
            <CustomScroll heightRelativeToParent="calc(100%)">
              <div style={{ paddingLeft: 24, paddingRight: 24 }}>{pipelineLogOutput}</div>
            </CustomScroll>
          </div>
        </div>
      </div>
    );
  }
}
