import { faCaretRight, faChartBar, faGavel, faMapMarker, faNewspaper, faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColorPicker, InputNumber } from 'antd';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { AppProps, ToastableProps } from '../../../App';
import { Label } from '../../elements/Label';
import { Switch } from '../../elements/Switch';

// Copy this from app-react-native/src/components/theme/ThemeProvider.tsx.
type Theme = {
  id: string;
  name: string;
  dark: boolean;
  vehicleType:
    | 'UNSPEC'
    | 'BOAT'
    | 'EBOAT'
    | 'MCYCLE'
    | 'EMCYCLE'
    | 'EBIKE'
    | 'CLASSCAR'
    | 'RV'
    | 'CAR'
    | 'MOTOR'
    | 'EMOTOR';
  colors: {
    highlightColor: string;

    primaryColor: string;
    secondaryColor: string;
    tertiaryColor: string;
    textOnPrimaryColor: string;
    textOnSecondaryColor: string;

    separatorColor: string;
    outlineColor: string;

    headerColor: string;
    statusBarColor: string;
    statusBarDark: boolean;

    modalBackground: string;
    shadedTiles: boolean;

    // Lowest elevation
    backgroundDeep: string;
    // Base background
    backgroundBase: string;
    // Slight elevation
    backgroundShallow: string;
    // Higher elevation
    backgroundSurface: string;
    // Prominent elevation (top and bottom navigator)
    backgroundHeader: string;
    // Highest Elevation
    backgroundTop: string;

    textOnBackgroundColor: string;
  };
  images: {
    // navbarBackground: ImageURISource | undefined;
    // navbarLogo: ImageURISource;
    // navbarLogoSquare: ImageURISource;
  };
  //   icons: {};
  sizes: {
    borderRadiusDefault: number;
  };
  fonts: {
    fontRegular: string;
    fontBold: string;
  };
  classes: {
    // fontRegular: StyleProp<TextStyle>;
    // textOnBackgroundColor: StyleProp<TextStyle>;
    // textBold: StyleProp<TextStyle>;
  };
};

const WhiteColor = '#FAFAFA';
const BlackColor = '#030303';
const SeparatorColor = '#888888';

const Scale = 0.75;

export const AppDesigner = (props: RouteComponentProps & AppProps & ToastableProps): React.ReactElement => {
  const [theme, setTheme] = useState<Theme>({
    id: 'io.ctechnology.theme.dark',
    name: 'c.technology dark',
    dark: true,
    vehicleType: 'UNSPEC',
    colors: {
      highlightColor: WhiteColor,

      primaryColor: '#4978bc',
      secondaryColor: '#1E90FF',
      tertiaryColor: '#FC0F9D',
      textOnPrimaryColor: WhiteColor,
      textOnSecondaryColor: WhiteColor,

      separatorColor: WhiteColor,
      outlineColor: WhiteColor,

      headerColor: WhiteColor,
      statusBarColor: '#0070BB',
      statusBarDark: true,

      modalBackground: 'rgba(255, 255, 255, 0.2)',
      shadedTiles: false,

      backgroundDeep: '#131313',
      backgroundBase: '#1A1A1A',
      backgroundShallow: '#3c4b61',
      backgroundSurface: '#505050',
      backgroundHeader: '#4978bc',
      backgroundTop: '#FEFEFE',

      textOnBackgroundColor: WhiteColor,
    },
    images: {
      // navbarBackground: undefined,
      // navbarLogo: NavbarLogo,
      // navbarLogoSquare: NavbarLogoSquare,
    },
    //   icons: {},
    sizes: {
      borderRadiusDefault: 12,
    },
    fonts: {
      fontRegular: 'Lato-Regular',
      fontBold: 'Lato-Black',
    },
    classes: {
      // fontRegular: { fontFamily: 'Lato-Regular' },
      // textOnBackgroundColor: { color: WhiteColor, fontFamily: 'Lato-Regular' },
      // textBold: { fontWeight: '500', fontFamily: 'Lato-Black' },
    },
  });

  return (
    <React.Fragment>
      <OverlayScrollbarsComponent
        style={{ height: '100%', padding: 24 }}
        options={{ scrollbars: { autoHide: 'move' } }}
      >
        <h1>AppDesigner</h1>
        <p>Design and configure the app.</p>

        <div style={{ display: 'flex', flexDirection: 'row', columnGap: 24 }}>
          <div style={{ flex: 0, display: 'flex', flexDirection: 'column', rowGap: 12, marginBottom: 24 }}>
            <div
              style={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                width: 430 * 0.75,
                height: 932 * 0.75,

                backgroundColor: '#e5e5f7',
                background: `repeating-linear-gradient(45deg, darkgrey 0px, darkgrey 6px, ${theme.colors.primaryColor} 6px, ${theme.colors.primaryColor} 12px)`,

                border: '1px solid #333333',
                borderRadius: 6,
                overflow: 'hidden',
              }}
            >
              <div
                id="statusbar"
                style={{
                  flex: 0,
                  width: '100%',
                  minHeight: 44 * Scale,
                  backgroundColor: theme.colors.statusBarColor,
                  paddingLeft: 12,
                  paddingRight: 12,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <FontAwesomeIcon
                  icon={faPlay}
                  style={{ color: theme.colors.statusBarDark ? WhiteColor : BlackColor }}
                />
              </div>
              <div
                id="header"
                style={{
                  flex: 0,
                  width: '100%',
                  minHeight: 96 * Scale,
                  backgroundColor: theme.colors.backgroundHeader,
                  color: theme.colors.headerColor,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                Dashboard
              </div>
              <div id="rest" style={{ width: '100%', backgroundColor: theme.colors.backgroundBase, flex: 1 }} />
              <div
                id="headeroverlay"
                style={{
                  position: 'absolute',
                  top: (44 + 96 - 24) * Scale,
                  left: 0,
                  width: 'calc(100% - 24px)',
                  height: 148 * Scale,
                  marginLeft: 12,
                  marginRight: 12,
                  borderRadius: theme.sizes.borderRadiusDefault,
                  backgroundColor: theme.colors.backgroundDeep,
                  padding: 12,
                  fontSize: '0.75rem',
                  color: theme.colors.textOnBackgroundColor,
                  display: 'flex',
                  flexDirection: 'row',
                  columnGap: 12,
                }}
              >
                <div
                  style={{
                    minWidth: 96 * Scale,
                    minHeight: 96 * Scale,
                    flex: 0,
                  }}
                >
                  <div
                    style={{
                      width: 96 * Scale,
                      height: 96 * Scale,
                      backgroundColor: BlackColor,
                      borderRadius: 96 * Scale,
                      borderWidth: 2,
                      borderStyle: 'solid',
                      borderColor: theme.colors.outlineColor,
                    }}
                  ></div>
                </div>
                <div
                  style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    color: theme.colors.textOnBackgroundColor,
                  }}
                >
                  <div>Lorem ipsum dolor sit amet, ...</div>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', columnGap: 12 }}>
                    <div
                      style={{
                        backgroundColor: theme.dark ? theme.colors.backgroundShallow : theme.colors.backgroundBase,
                        color: theme.colors.textOnBackgroundColor,
                        paddingLeft: 12,
                        paddingRight: 12,
                        paddingTop: 8,
                        paddingBottom: 8,
                        borderRadius: theme.sizes.borderRadiusDefault,
                      }}
                    >
                      Weather
                    </div>
                    <div
                      style={{
                        backgroundColor: theme.dark ? theme.colors.backgroundShallow : theme.colors.backgroundBase,
                        color: theme.colors.textOnBackgroundColor,
                        paddingLeft: 12,
                        paddingRight: 12,
                        paddingTop: 8,
                        paddingBottom: 8,
                        borderRadius: theme.sizes.borderRadiusDefault,
                      }}
                    >
                      Notif.
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="rectbutton"
                style={{
                  position: 'absolute',
                  top: (44 + 96 - 24 + 148 + 12) * Scale,
                  left: 0,
                  width: 'calc(100% - 36px)',
                  height: 128 * Scale,
                  marginLeft: 18,
                  marginRight: 18,
                  borderRadius: theme.sizes.borderRadiusDefault,
                  backgroundColor: theme.colors.backgroundShallow,
                  padding: 12,
                  fontSize: '0.75rem',
                  color: theme.colors.textOnBackgroundColor,
                }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod ...
              </div>

              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(2, 1fr)',
                  gap: 12,
                  position: 'absolute',
                  top: (44 + 96 - 24 + 148 + 12 + 128 + 18) * Scale,
                  left: 0,
                  width: 'calc(100% - 36px)',
                  marginLeft: 18,
                  marginRight: 18,
                  fontSize: '0.75rem',
                  color: theme.colors.textOnBackgroundColor,
                }}
              >
                <div
                  style={{
                    backgroundColor: theme.colors.backgroundShallow,
                    borderRadius: theme.sizes.borderRadiusDefault,
                    padding: 12,
                    aspectRatio: '1 / 1',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <div>
                    <FontAwesomeIcon
                      icon={faNewspaper}
                      style={{ color: theme.colors.textOnBackgroundColor }}
                      size="4x"
                    />
                  </div>
                  <div>Service A</div>
                </div>
                <div
                  style={{
                    backgroundColor: theme.colors.backgroundShallow,
                    borderRadius: theme.sizes.borderRadiusDefault,
                    padding: 12,
                    aspectRatio: '1 / 1',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <div>
                    <FontAwesomeIcon
                      icon={faChartBar}
                      style={{ color: theme.colors.textOnBackgroundColor }}
                      size="4x"
                    />
                  </div>
                  <div>Service B</div>
                </div>
                <div
                  style={{
                    backgroundColor: theme.colors.backgroundShallow,
                    borderRadius: theme.sizes.borderRadiusDefault,
                    padding: 12,
                    aspectRatio: '1 / 1',
                  }}
                >
                  C
                </div>
                <div
                  style={{
                    backgroundColor: theme.colors.backgroundShallow,
                    borderRadius: theme.sizes.borderRadiusDefault,
                    padding: 12,
                    aspectRatio: '1 / 1',
                  }}
                >
                  D
                </div>
                <div
                  style={{
                    backgroundColor: theme.colors.backgroundShallow,
                    borderRadius: theme.sizes.borderRadiusDefault,
                    padding: 12,
                    aspectRatio: '1 / 1',
                  }}
                >
                  E
                </div>
                <div
                  style={{
                    backgroundColor: theme.colors.backgroundShallow,
                    borderRadius: theme.sizes.borderRadiusDefault,
                    padding: 12,
                    aspectRatio: '1 / 1',
                  }}
                >
                  F
                </div>
              </div>

              <div
                id="bottombar"
                style={{
                  position: 'absolute',
                  bottom: 0,
                  width: '100%',
                  height: 64 * Scale,
                  backgroundColor: theme.colors.backgroundDeep,
                }}
              >
                <div
                  id="bubble"
                  style={{
                    position: 'absolute',
                    bottom: 8,
                    left: 32,
                    width: 48 * Scale,
                    height: 48 * Scale,
                    borderRadius: 24 * Scale,
                    backgroundColor: theme.colors.primaryColor,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <FontAwesomeIcon icon={faGavel} style={{ color: theme.colors.textOnPrimaryColor }} />
                </div>
                <div
                  id="emptybubble"
                  style={{
                    position: 'absolute',
                    bottom: 8,
                    left: 32 + 48 * Scale + 36,
                    width: 48 * Scale,
                    height: 48 * Scale,
                    borderRadius: 24 * Scale,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <FontAwesomeIcon icon={faMapMarker} style={{ color: theme.colors.textOnBackgroundColor }} />
                </div>
              </div>
            </div>
            {/* Second phone screen */}
            <div
              style={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                width: 430 * 0.75,
                height: 932 * 0.75,

                backgroundColor: '#e5e5f7',
                background: `repeating-linear-gradient(45deg, darkgrey 0px, darkgrey 6px, ${theme.colors.primaryColor} 6px, ${theme.colors.primaryColor} 12px)`,

                border: '1px solid #333333',
                borderRadius: 6,
                overflow: 'hidden',
              }}
            >
              <div
                id="statusbar"
                style={{
                  flex: 0,
                  width: '100%',
                  minHeight: 44 * Scale,
                  backgroundColor: theme.colors.statusBarColor,
                  paddingLeft: 12,
                  paddingRight: 12,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <FontAwesomeIcon
                  icon={faPlay}
                  style={{ color: theme.colors.statusBarDark ? WhiteColor : BlackColor }}
                />
              </div>
              <div
                id="header"
                style={{
                  flex: 0,
                  width: '100%',
                  minHeight: 96 * Scale,
                  backgroundColor: theme.colors.backgroundHeader,
                  color: theme.colors.headerColor,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                Dashboard
              </div>
              <div id="rest" style={{ width: '100%', backgroundColor: theme.colors.backgroundBase, flex: 1 }} />
              <div
                id="headeroverlay"
                style={{
                  position: 'absolute',
                  top: (44 + 96 - 24) * Scale,
                  left: 0,
                  width: 'calc(100% - 24px)',
                  height: 64 * Scale,
                  marginLeft: 12,
                  marginRight: 12,
                  borderRadius: theme.sizes.borderRadiusDefault,
                  backgroundColor: theme.colors.backgroundDeep,
                  padding: 12,
                  fontSize: '0.75rem',
                  color: theme.colors.textOnBackgroundColor,
                  display: 'flex',
                  flexDirection: 'row',
                  columnGap: 12,
                }}
              >
                <div
                  style={{
                    minWidth: 36 * Scale,
                    minHeight: 36 * Scale,
                    flex: 0,
                  }}
                >
                  <div
                    style={{
                      width: 36 * Scale,
                      height: 36 * Scale,
                      backgroundColor: BlackColor,
                      borderRadius: 36 * Scale,
                      borderWidth: 2,
                      borderStyle: 'solid',
                      borderColor: theme.colors.outlineColor,
                    }}
                  ></div>
                </div>
                <div
                  style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    color: theme.colors.textOnBackgroundColor,
                  }}
                >
                  <div>Lorem ipsum dolor sit amet, ...</div>
                </div>
              </div>

              <div
                id="form"
                style={{
                  position: 'absolute',
                  top: (44 + 96 - 24 + 64 + 12) * Scale,
                  left: 0,
                  width: 'calc(100% - 24px)',
                  height: 300 * Scale,
                  marginLeft: 12,
                  marginRight: 12,
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: 12,
                }}
              >
                <div style={{ fontSize: '0.8rem', color: theme.colors.textOnBackgroundColor }}>Vorname</div>
                <div
                  style={{
                    fontSize: '0.9rem',
                    color: theme.colors.textOnBackgroundColor,
                    backgroundColor: theme.colors.backgroundSurface,
                    borderRadius: theme.sizes.borderRadiusDefault,
                    paddingLeft: 12,
                    paddingRight: 12,
                    paddingTop: 6,
                    paddingBottom: 6,
                  }}
                >
                  Paul
                </div>
                <div style={{ fontSize: '0.8rem', color: theme.colors.textOnBackgroundColor }}>Nachname</div>
                <div
                  style={{
                    fontSize: '0.9rem',
                    color: theme.colors.textOnBackgroundColor,
                    backgroundColor: theme.colors.backgroundSurface,
                    borderRadius: theme.sizes.borderRadiusDefault,
                    paddingLeft: 12,
                    paddingRight: 12,
                    paddingTop: 6,
                    paddingBottom: 6,
                  }}
                >
                  Meier
                </div>
                <div style={{ fontSize: '0.8rem', color: theme.colors.textOnBackgroundColor }}>E-Mail</div>
                <div
                  style={{
                    fontSize: '0.9rem',
                    color: theme.colors.textOnBackgroundColor,
                    backgroundColor: theme.colors.backgroundSurface,
                    borderRadius: theme.sizes.borderRadiusDefault,
                    paddingLeft: 12,
                    paddingRight: 12,
                    paddingTop: 6,
                    paddingBottom: 6,
                    opacity: 0.5,
                  }}
                >
                  blabla@blabla.com
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: theme.colors.primaryColor,
                    color: theme.colors.textOnPrimaryColor,
                    borderRadius: theme.sizes.borderRadiusDefault,
                    minHeight: 42 * Scale,
                  }}
                >
                  Speichern
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'transparent',
                    color: theme.colors.textOnBackgroundColor,
                    border: '1px solid ' + theme.colors.primaryColor,
                    borderRadius: theme.sizes.borderRadiusDefault,
                    minHeight: 42 * Scale,
                  }}
                >
                  Abbrechen
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: theme.colors.backgroundShallow,
                    color: theme.colors.textOnBackgroundColor,
                    minHeight: 64 * Scale,
                    borderRadius: theme.sizes.borderRadiusDefault,
                    paddingLeft: 12,
                    paddingRight: 12,
                  }}
                >
                  <div>More Information</div>
                  <FontAwesomeIcon icon={faCaretRight} style={{ color: theme.colors.textOnBackgroundColor }} />
                </div>

                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: theme.colors.backgroundShallow,
                    borderRadius: theme.sizes.borderRadiusDefault,
                    padding: 12,
                    flexDirection: 'column',
                    rowGap: 12,
                  }}
                >
                  <div style={{ width: '100%', fontSize: '0.8rem', color: theme.colors.textOnBackgroundColor }}>
                    Embedded Textbox
                  </div>
                  <div
                    style={{
                      width: '100%',
                      fontSize: '0.9rem',
                      color: theme.colors.textOnBackgroundColor,
                      backgroundColor: theme.colors.backgroundSurface,
                      borderRadius: theme.sizes.borderRadiusDefault,
                      paddingLeft: 12,
                      paddingRight: 12,
                      paddingTop: 6,
                      paddingBottom: 6,
                    }}
                  >
                    Value
                  </div>
                </div>
              </div>

              <div
                id="bottombar"
                style={{
                  position: 'absolute',
                  bottom: 0,
                  width: '100%',
                  height: 64 * Scale,
                  backgroundColor: theme.colors.backgroundDeep,
                }}
              >
                <div
                  id="bubble"
                  style={{
                    position: 'absolute',
                    bottom: 8,
                    left: 32,
                    width: 48 * Scale,
                    height: 48 * Scale,
                    borderRadius: 24 * Scale,
                    backgroundColor: theme.colors.primaryColor,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <FontAwesomeIcon icon={faGavel} style={{ color: theme.colors.textOnPrimaryColor }} />
                </div>
                <div
                  id="emptybubble"
                  style={{
                    position: 'absolute',
                    bottom: 8,
                    left: 32 + 48 * Scale + 36,
                    width: 48 * Scale,
                    height: 48 * Scale,
                    borderRadius: 24 * Scale,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <FontAwesomeIcon icon={faMapMarker} style={{ color: theme.colors.textOnBackgroundColor }} />
                </div>
              </div>
            </div>
          </div>
          <div style={{ flex: 1 }}>
            <Label label="highlightColor" />
            <ColorPicker
              value={theme.colors.highlightColor}
              onChange={(v) => setTheme({ ...theme, colors: { ...theme.colors, highlightColor: v.toHexString() } })}
              showText
            />
            <Label label="primaryColor" />
            <ColorPicker
              value={theme.colors.primaryColor}
              onChange={(v) => setTheme({ ...theme, colors: { ...theme.colors, primaryColor: v.toHexString() } })}
              showText
            />
            <Label label="secondaryColor" />
            <ColorPicker
              value={theme.colors.secondaryColor}
              onChange={(v) => setTheme({ ...theme, colors: { ...theme.colors, secondaryColor: v.toHexString() } })}
              showText
            />
            <Label label="tertiaryColor" />
            <ColorPicker
              value={theme.colors.tertiaryColor}
              onChange={(v) => setTheme({ ...theme, colors: { ...theme.colors, tertiaryColor: v.toHexString() } })}
              showText
            />
            <Label label="textOnPrimaryColor" />
            <ColorPicker
              value={theme.colors.textOnPrimaryColor}
              onChange={(v) => setTheme({ ...theme, colors: { ...theme.colors, textOnPrimaryColor: v.toHexString() } })}
              showText
            />
            <Label label="textOnSecondaryColor" />
            <ColorPicker
              value={theme.colors.textOnSecondaryColor}
              onChange={(v) =>
                setTheme({ ...theme, colors: { ...theme.colors, textOnSecondaryColor: v.toHexString() } })
              }
              showText
            />
            <Label label="separatorColor" />
            <ColorPicker
              value={theme.colors.separatorColor}
              onChange={(v) => setTheme({ ...theme, colors: { ...theme.colors, separatorColor: v.toHexString() } })}
              showText
            />
            <Label label="outlineColor" />
            <ColorPicker
              value={theme.colors.outlineColor}
              onChange={(v) => setTheme({ ...theme, colors: { ...theme.colors, outlineColor: v.toHexString() } })}
              showText
            />
            <Label label="headerColor" />
            <ColorPicker
              value={theme.colors.headerColor}
              onChange={(v) => setTheme({ ...theme, colors: { ...theme.colors, headerColor: v.toHexString() } })}
              showText
            />
            <Label label="statusBarColor" />
            <ColorPicker
              value={theme.colors.statusBarColor}
              onChange={(v) => setTheme({ ...theme, colors: { ...theme.colors, statusBarColor: v.toHexString() } })}
              showText
            />
            <Switch
              label="statusBarDark"
              checked={theme.colors.statusBarDark}
              onChange={(v) => setTheme({ ...theme, colors: { ...theme.colors, statusBarDark: v } })}
              style={{ marginBottom: 24 }}
            />
            <Label label="modalBackground" />
            <ColorPicker
              value={theme.colors.modalBackground}
              onChange={(v) => setTheme({ ...theme, colors: { ...theme.colors, modalBackground: v.toHexString() } })}
              showText
            />
            <Switch
              label="shadedTiles"
              checked={theme.colors.shadedTiles}
              onChange={(v) => setTheme({ ...theme, colors: { ...theme.colors, shadedTiles: v } })}
              style={{ marginBottom: 24 }}
            />
            <Label label="backgroundDeep" />
            <ColorPicker
              value={theme.colors.backgroundDeep}
              onChange={(v) => setTheme({ ...theme, colors: { ...theme.colors, backgroundDeep: v.toHexString() } })}
              showText
            />
            <Label label="backgroundBase" />
            <ColorPicker
              value={theme.colors.backgroundBase}
              onChange={(v) => setTheme({ ...theme, colors: { ...theme.colors, backgroundBase: v.toHexString() } })}
              showText
            />
            <Label label="backgroundShallow" />
            <ColorPicker
              value={theme.colors.backgroundShallow}
              onChange={(v) => setTheme({ ...theme, colors: { ...theme.colors, backgroundShallow: v.toHexString() } })}
              showText
            />
            <Label label="backgroundSurface" />
            <ColorPicker
              value={theme.colors.backgroundSurface}
              onChange={(v) => setTheme({ ...theme, colors: { ...theme.colors, backgroundSurface: v.toHexString() } })}
              showText
            />
            <Label label="backgroundHeader" />
            <ColorPicker
              value={theme.colors.backgroundHeader}
              onChange={(v) => setTheme({ ...theme, colors: { ...theme.colors, backgroundHeader: v.toHexString() } })}
              showText
            />
            <Label label="backgroundTop" />
            <ColorPicker
              value={theme.colors.backgroundTop}
              onChange={(v) => setTheme({ ...theme, colors: { ...theme.colors, backgroundTop: v.toHexString() } })}
              showText
            />
            <Label label="textOnBackgroundColor" />
            <ColorPicker
              value={theme.colors.textOnBackgroundColor}
              onChange={(v) =>
                setTheme({ ...theme, colors: { ...theme.colors, textOnBackgroundColor: v.toHexString() } })
              }
              showText
            />
            <Label label="borderRadiusDefault" />
            <InputNumber
              min={0}
              max={64}
              defaultValue={6}
              changeOnWheel
              value={theme.sizes.borderRadiusDefault}
              onChange={(e) => e && setTheme({ ...theme, sizes: { ...theme.sizes, borderRadiusDefault: e } })}
            />
          </div>
        </div>
      </OverlayScrollbarsComponent>
    </React.Fragment>
  );
};
