import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { AppProps, ToastableProps } from '../App';
import { apiFetch } from '../util/APIFetcher';

export const GeofenceCopyScreen = (props: RouteComponentProps & AppProps & ToastableProps): React.ReactElement => {
  const [userIdFrom, setUserIdFrom] = useState('');
  const [userIdTo, setUserIdTo] = useState('');

  const initiateCopy = () => {
    if (userIdFrom.trim().length === 0 || userIdTo.trim().length === 0) {
      alert('User IDs must not be empty.');
    }

    const userToken = props.appState.userToken;
    apiFetch(
      '/api/v1.0/admin/geofence/ops/copy-between-accounts',
      'POST',
      {
        user_id_from: userIdFrom,
        user_id_to: userIdTo,
      },
      userToken
    );
    console.log(`Copy from ${userIdFrom} to ${userIdTo}.`);
  };

  return (
    <div style={{ padding: 24 }}>
      <div>
        <div className="mb-3">
          <label htmlFor="exampleInputEmail1" className="form-label">
            Copy geofences <b>from</b> user with ID:
          </label>
          <input
            type="text"
            className="form-control"
            name="userIdFrom"
            value={userIdFrom}
            onChange={(e) => setUserIdFrom(e.target.value)}
            style={{ maxWidth: 480 }}
          ></input>
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label">
            Copy geofences <b>to</b> user with ID:
          </label>
          <input
            type="text"
            className="form-control"
            name="userIdTo"
            value={userIdTo}
            onChange={(e) => setUserIdTo(e.target.value)}
            style={{ maxWidth: 480 }}
          ></input>
        </div>
        <button className="btn btn-primary" onClick={initiateCopy}>
          Copy now
        </button>
      </div>
    </div>
  );
};
